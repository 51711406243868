import React from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import NavBar from "../component/layout/nav";

// images
import iconImg from "../images/enterprise/icon.svg";
import bgImage from "../images/enterprise/bg.png";
import bgImageMobile from "../images/enterprise/bgMobile.svg";
import heroImageBg from "../images/enterprise/heroBg.svg";
import heroImage from "../images/enterprise/enterpriseimagei.png";
import img from "../images/enterprise/dietary.svg";
import bg03 from "../images/enterprise/bg03.svg";
import down from "../images/enterprise/down.png";

import "../style/css/enterprise.css";
import CustomButton from "../component/ui/customButton";
import ImageStack from "../component/ui/imageStack";

const models = [
  { icon: iconImg, title: "Fraud prevention", full: true },
  { icon: iconImg, title: "Gov ID verification" },
  { icon: iconImg, title: "Biometric verification" },
  { icon: iconImg, title: "IP and device check" },
  { icon: iconImg, title: "Phone number check" },
];

const tableData = [
  {
    title: "Veriifcation",
    text: [
      "Bank Verification Number",
      "National Identification Number",
      "Drivers License",
      "International Passport",
      "Corporate Affairs Commission (CAC)",
    ],
  },
  {
    title: "Bills Payment",
    text: ["Airtime Purchase", "Data Purchase", "Electricity", "Cable TV"],
  },
  {
    title: "Banking",
    text: [
      "Resolve Account Number",
      "Fetch Bank",
      "Bank Transfer",
      "Virtual Card",
      "Physical Card",
      "Bank Statement",
    ],
  },
  {
    title: "Messaging",
    text: ["SMS OTP", "WhatsApp OTP"],
  },
];

export default function Enterprisepage() {

  return (
    <Box>
      <Box
        overflow={"hidden"}
        sx={{
          backgroundImage: {
            md: `url(${bgImage})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundPosition: { xs: "right", md: "center top" },
          backgroundSize: { xs: "contain", sm: "cover" },
        }}
        pb={{ xs: "39px", md: "0" }}
      >
        <Box
          textAlign="left"
          sx={{
            backgroundImage: {
              xs: `url(${bgImageMobile})`,
              md: `url(${heroImageBg})`,
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundSize: { xs: "70.60vw 100%", sm: "50% 100%", md: "100%" },
          }}
          px={{ xs: "24px", sm: "70px", md: "7.29vw" }}
          // pr={{ xs: "40px", sm: "64px", md: "6.71vw" }}
        >
          <Box>
            <NavBar  dark />
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            columnGap={{ xs: "24px", sm: "38px", md: "3.99vw" }}
            pb={{ xs: "40px", md: "0px" }}
          >
            <Box
              // ml={{xs:"44px", sm:"70px", md:"7.29vw"}}
              mt={{ xs: "35px", sm: "56px", md: "5.84vw" }}
              sx={{ maxWidth: { xs: "77.80vw", md: "36.46vw" } }}
            >
              <Typography variant="h3" sx={{ fontWeight: "500" }}>
                BlacBox For <br />
                Global Enterprise
              </Typography>
              <Typography
                mt={{ xs: "2px", sm: "4px", md: "0.41vw" }}
                maxWidth={{ md: "29.28vw" }}
                paragraph
                sx={{ fontWeight: "400" }}
                mb={{ xs: "11px", sm: "18px", md: "1.91vw" }}
              >
                We have a mission to emerge as the global API service frontier,
                and we are aiming for worldwide excellence through partnership
                and our B2B structure
              </Typography>
              <CustomButton text="Explore Tools" dark />
            </Box>
            <Box
              display={{ xs: "none", md: "block" }}
              maxWidth={{ md: "45.20vw" }}
            >
              <img src={heroImage} width="100%" alt="" />
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            textAlign: { xs: "left", sm: "center" },
            display: "flex",
            justifyContent: "center",
            fontWeight: "400",
            px: { xs: "23px", sm: "0" },
            mx: "auto",
            maxWidth: { sm: "371px", md: "38.77vw" },
            marginTop: { xs: "20.40vw", md: "2.08vw" },
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "400" }}>
              Global Takeover,
              <br /> International Reach
            </Typography>
            <Typography
              mt={{ xs: "16px", md: "0.58vw" }}
              // variant="subtitle1"
              sx={{
                fontWeight: "400",
                fontSize: { xs: "13px", md: "1.04vw" },
                // fontSize: "16px",
              }}
            >
              The path to greatness is implemented into the products we provide
              to all business owners who use Blacbox. It is our goal to provide
              solutions to various businesses all over the world.
            </Typography>
            <Typography
              fontSize={{ xs: "0.55em", md: "0.79vw" }}
              mt={{ xs: "10px", sm: "17px", md: "1.74vw" }}
            >
              Explore all countries where Blacbox is commercially available..
            </Typography>
            <img src={down} alt="" width="50px" />
          </Box>
        </Box>

        <Stack
          id="global"
          flexDirection={{ xs: "column", md: "row" }}
          columnGap={{ xs: "36px", sm: "58px", md: "6.02vw" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          pl={{ xs: "24px", sm: "70px", md: "7.29vw" }}
          mb={{ xs: "35px", sm: "56px", md: "5.84vw" }}
          pr={{ xs: "29px", sm: "47px", md: "4.86vw" }}
        >
          <Box
            mt={{ xs: "43px", md: "6.13vw" }}
            maxWidth={{ xs: "80.00vw", md: "39.99vw" }}
          >
            <Typography variant="h3" sx={{ fontWeight: "400" }}>
              Centered on fostering trust and credibility across digital
              business models.
            </Typography>
            <Typography
              variant="body2"
              mt={{ xs: "16px", md: "0.58vw" }}
              sx={{
                color: "black",
                maxWidth: { xs:"80.00vw", md:"26.04vw"},
              }}
            >
              With a focus on fostering trust and credibility among digital
              businesses, Blacbox's API suite makes it simple to safely access
              client Credentials.
            </Typography>
          </Box>

          <Grid
            container
            rowSpacing={{ xs: "14px", sm: "22px", md: "2.31vw" }}
            mt={{ xs: "43px", md: "2.14vw" }}
            columnSpacing={{ xs: "0", sm: "20px", md: "2.08vw" }}
            justifyContent={{ xs: "flex-start", sm: "center" }}
          >
            {models.map((item) => (
              <Grid item key={item.title} xs={11} sm={item.full ? 7 : 6}>
                <Stack
                  direction="row"
                  width="100%"
                  minHeight={{ xs: "45px", sm: "49px", md: "5.09vw" }}
                  sx={{
                    background: "black",
                    color: "white",
                    borderRadius: { xs: "12px", md: "1.39vw" },
                    // maxWidth: { xs: "253px", md: "19.91vw" },
                  }}
                  alignItems="center"
                >
                  <Box
                    width="100%"
                    maxWidth={{ xs: "28px", sm: "31px", md: "3.24vw" }}
                  >
                    <img src={iconImg} alt="" width="100%" />
                  </Box>
                  <Typography> {item.title} </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>

      <Box
        px={{ xs: "24px", sm: "70px", md: "7.29vw" }}
        mt={{ xs: "34px", sm: "54px", md: "5.61vw" }}
        pr={{ md: "5.84vw" }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          columnGap={{ xs: "34px", sm: "54px", md: "5.67vw" }}
          rowGap={{ xs: "37px" }}
          justifyContent="space-between"
        >
          <Box maxWidth={{ md: "37.33vw" }} width="100%">
            <Box>
              <Typography sx={{ color: "#E2C703",fontSize:{xs:"1.1em",md:"1em"}, fontWeight:{xs:500,sm:400} }}>
                Spotlighted Project
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "400",
                  marginTop: { xs: "6px", sm: "9px", md: "0.93vw" },
                }}
              >
                Dietarify transforms its Nutritional Service  experience with Blacbox.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: { xs: "7px", sm: "11px", md: "1.10vw" },
                }}
              >
                Dietarify, a healthtech company, has conscripted the use of
                BlacBox offerings for its application. through simple and
                personalized nutrition, the company is able to successfully
                perform kyc verification and fianacial services using Blacbox.
              </Typography>
            </Box>
          </Box>
          <Box maxWidth={{ md: "45.43vw" }} width="100%">
            <img src={img} alt="" width="100%" style={{ width: "100%" }} />
          </Box>
        </Stack>
      </Box>

      <Box
        mt={{ xs: "28px", sm: "44px", md: "4.63vw" }}
        mb={{ xs: "39px", md: "0.00vw" }}
        display={{ xs: "none", sm: "block" }}
      >
        <Box
          minHeight={{ xs: "206px", sm: "329px", md: "34.38vw" }}
          container
          sx={{
            padding: { xs: "25px", sm: "39px", md: "4.11vw" },
            background: `url(${bg03})`,
            backgroundSize: "cover",
          }}
          color="white"
          // alignItems="center"
        >
          <Box
            textAlign={"center"}
            maxWidth={{ xs: "297px", sm: "475px", md: "49.54vw" }}
            mx="auto"
          >
            <Typography variant="h3">
              API Services Provided By Blacbox{" "}
            </Typography>
            <Typography
              mt={{ xs: "7px", sm: "11px", md: "1.16vw" }}
              variant="h5"
            >
              We have implemented a wide range of API services that enable
              enterprises to utilize our services
            </Typography>
          </Box>
        </Box>
        <Stack
          mt={{ xs: "-63px", sm: "-100px", md: "-10.42vw" }}
          maxWidth={{ xs: "466px", sm: "744px", md: "77.66vw" }}
          mx="auto"
          bgcolor="#E2C703"
        >
          <Stack
            color="#E2C703"
            direction="row"
            px={{ xs: "15px", sm: "24px", md: "2.55vw" }}
            sx={{
              background:
                "linear-gradient(90.44deg, #020202 -3.51%, rgba(91, 67, 3, 0.47) 106.68%)",
            }}
            alignItem="center"
            columnGap="20px"
            justifyContent={"space-between"}
          >
            <Box
              width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
              py={{ xs: "10px", sm: "17px", md: "1.74vw" }}
              pl={{ xs: "40px", sm: "64px", md: "90px" }}
              textAlign="center"
              flexGrow={1}
            >
              <Typography variant="h5"> API Services</Typography>
            </Box>
            <Box>
              <Divider
                orientation="vertical"
                // flexItem
                sx={{ borderColor: "#9F8C00" }}
              />
            </Box>

            <Box
              width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
              flexGrow={1}
              py={{ xs: "10px", sm: "17px", md: "1.74vw" }}
              mr={4}
            >
              <Typography variant="h5"> Service Information</Typography>

              <Box ml={{ xs: "22px", sm: "35px", md: "49px" }}></Box>
            </Box>
          </Stack>
          {tableData.map((item, index) => (
            <Stack
              mx={{ xs: "15px", sm: "24px", md: "2.55vw" }}
              borderRadius="50px"
              direction="row"
              // minHeight={{ xs: "58px", sm: "93px", md: "131px" }}
              border={index % 2 === 0 ? "" : "1px solid #9F8C00"}
              containere
              alignItem="center"
              columnGap="20px"
              // minHeight={{ xs: "304px", sm: "485px", md: "687px" }}

              justifyContent={"space-between"}
            >
              <Box
                width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
                py={{ xs: "7px", sm: "11px", md: "1.16vw" }}
                pl={{ xs: "40px", sm: "64px", md: "90px" }}
                flexGrow={1}
              >
                <Typography variant="h5"> {item.title} </Typography>
              </Box>
              <Box>
                <Divider
                  orientation="vertical"
                  // flexItem
                  sx={{ borderColor: "#9F8C00" }}
                />
              </Box>

              <Box
                width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
                flexGrow={1}
                py={{ xs: "7px", sm: "11px", md: "1.16vw" }}
                mr={4}
              >
                <Box
                  fontSize={{ xs: "12px", sm: "16px", md: "1.16vw" }}
                  ml={{ xs: "22px", sm: "35px", md: "49px" }}
                >
                  <ul>
                    {item.text.map((child) => (
                      <li style={{ fontSize: "inherit" }}> {child} </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Stack>
          ))}
          <Stack
            mx={{ xs: "15px", sm: "24px", md: "2.55vw" }}
            borderRadius="50px"
            direction="row"
            container
            alignItem="center"
            columnGap="20px"
            // minHeight={{ xs: "304px", sm: "485px", md: "687px" }}

            justifyContent={"space-between"}
          >
            <Box
              width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
              py={{ xs: "7px", sm: "11px", md: "1.16vw" }}
              pl={{ xs: "40px", sm: "64px", md: "90px" }}
              flexGrow={1}
            ></Box>
            <Box>
              <Divider
                orientation="vertical"
                // flexItem
                sx={{ borderColor: "#9F8C00" }}
              />
            </Box>

            <Box
              width={{ xs: "228px", sm: "364px", md: "38.02vw" }}
              flexGrow={1}
              py={{ xs: "7px", sm: "11px", md: "1.16vw" }}
              mr={4}
            >
              <Box ml={{ xs: "22px", sm: "35px", md: "49px" }}></Box>
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box
        mb={{ xs: "35px", sm: "55px", md: "5.79vw" }}
        mt={{ xs: "79px", sm: "83px", md: "8.68vw" }}
        sx={{ display: { xs: "none", ta: "block" } }}
      >
        <Typography
          mt={{ xs: "17px", sm: "28px", md: "2.89vw" }}
          variant={"h3"}
          sx={{
            letterSpacing: { md: "0.485em" },
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Businesses Powered By BlacBox
        </Typography>
        <ImageStack />
      </Box>
    </Box>
  );
}
