import React from "react";
import { Box, Typography, CardContent, Card, Stack } from "@mui/material";
import CustomButton from "../../component/ui/customButton";

export function MediaCard({ text, title, color, width, height, hoverColor }) {
  return (
    <Card
      sx={{
        maxWidth: width ? width : { md: "293px" },
        background: color,
        minHeight: height ? height : 185,
        display: "flex",
        flexDirection: "column",
        borderRadius: { xs: "8px", md: "0.58vw" },
        justifyContent: "center",
        alignItems: "center",
        color: color === "#000000" ? "#ffffff" : "text.secondary",
        // marginTop: "30px",
        ":hover": hoverColor
          ? {
              background: hoverColor,
              color: "black",
            }
          : {},
      }}
    >
      <CardContent
        sx={{
          p: { xs: "31px", md: "3.07vw" },
        }}
      >
        {title !== undefined ? (
          <Typography
            gutterBottom
            variant="h5"
            fontWeight="600"
            component="div"
            fontSize={{ xs: "19px", md: "1.39vw" }}
            // color={color === "#000000" ? "#ffffff" : "text.secondary"}
            textAlign={"center"}
          >
            {title}
          </Typography>
        ) : null}
        {/* <p>man</p> */}
        <Typography
          variant="subtitle1"
          // color={color === "#000000" ? "#ffffff" : "text.secondary"}
          fontWeight={"400"}
          fontSize={{ xs: "13px", md: "1.04vw" }}
          // lineHeight={"134%"}
          letterSpacing={"0.007em"}
          textAlign={"center"}
          mt={{ xs: "8px", sm: " 11px", md: "1.16vw" }}
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}

// const data = {
//   getStarted: [
//     { text: "A dependable API simulation and integration" },
//     {
//       text: "Competitive pricing that is both affordable and simple to maintain ",
//     },
//     { text: "A friendly and welcoming support system is always available." },
//     { text: "Documentation that walk you through the provided services" },
//   ],
// }

export default function GetStarted() {
  return (
    <Box>
      <Stack
        direction={{ xs: "column", md: "row" }}
        // width={{ xs: "100%", sm: "100%", md: "75%" }}
        // margin={"0 auto"}
        mt={{ xs: "55px", sm: " 55px", md: "5.79vw" }}
        justifyContent={{ md: "flex-end" }}
        alignItems={{ md: "center" }}
        // gap={{ xs: "23px", sm: "57px", md: "5.9%" }}
        // px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
        pl={{ xs: "24px", sm: " 137px", md: "14.29vw" }}
        // pr={{ xs: "24px", sm: " 90px", md: "9.38vw" }}
        pr={{ xs: "24px", sm: "55px", md: "5.79vw" }}
      >
        <Box width="100%" mr={{ md: "6.02vw" }} maxWidth={{ md: "24.31vw" }}>
          <Typography
            variant="h4"
            color={"#000000"}
            fontWeight={"500"}
            fontSize={{ xs: "24px", md: "2.31vw" }}
            // lineHeight={"49px"}
            // letterSpacing={"0.01em"}
          >
            Powerful reasons to choose Blacbox
          </Typography>
          <Typography
            color={"#000000"}
            fontWeight={"400"}
            fontSize={{ xs: "13px", md: "1.04vw" }}
            lineHeight={"134.5%"}
            letterSpacing={"0.007em"}
            mt={{ xs: "9px", sm: " 11px", md: "1.16vw" }}
          >
            We don't just deliver; we think ahead and use new models and
            frameworks to provide you with the best possible service
          </Typography>
          <Box
            mt={{ xs: "36px", md: "2.66vw" }}
            display={{ xs: "none", md: "block" }}
          >
            <CustomButton
              text="Get Started"
              href="https://portal.blacbox.app/register"
              target="_blank"
            />
          </Box>
        </Box>
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          // width={{ xs: "100%", md: "60%" }}
          width="100%"
          display={{ xs: "none", md: "flex" }}
          justifyContent={{ sm: "flex-end" }}
          // columnGap={{ xs: "29px", md: "0px" }}
          columnGap={{ xs: "29px", md: "2.14vw" }}
        >
          <Stack
            width="100%"
            rowGap={{ xs: "29px", md: "2.14vw" }}
            maxWidth={{ xs: "298px", md: "21.93vw" }}
            // maxheight={{ xs: "204px", md: "14.99vw" }}
          >
            <MediaCard
              text={"A dependable API simulation and integration"}
              color={"#000000"}
              width={{ xs: "298px", md: "100%" }}
              // width={{ xs: "298px", md: "21.93vw" }}
              height={{ xs: "204px", md: "14.99vw" }}
              // height={{ xs: "204px", md: "100%" }}
            />
            <MediaCard
              text={
                "Competitive pricing that is both affordable and simple to maintain "
              }
              color={"#FEFBE7"}
              width={{ xs: "298px", md: "100%" }}
              // width={{ xs: "298px", md: "21.93vw" }}
              // height={{ xs: "204px", md: "100%" }}
              height={{ xs: "204px", md: "14.99vw" }}
            />
          </Stack>
          <Stack
            width="100%"
            maxWidth={{ xs: "298px", md: "21.93vw" }}
            rowGap={{ xs: "29px", md: "2.14vw" }}
            mt={{ md: "1.56vw" }}
          >
            <MediaCard
              text={
                "A friendly and welcoming support system is always available."
              }
              color={"#E2C703"}
              width={{ xs: "298px", md: "100%" }}
              // width={{ xs: "298px", md: "21.93vw" }}
              // height={{ xs: "204px", md: "100%" }}
              height={{ xs: "204px", md: "14.99vw" }}
            />
            <MediaCard
              text={"Documentation that walk you through the provided services"}
              color={"#000000"}
              width={{ xs: "298px", md: "100%" }}
              // width={{ xs: "298px", md: "21.93vw" }}
              // height={{ xs: "204px", md: "100%" }}
              height={{ xs: "204px", md: "14.99vw" }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Box
        sx={{
          padding: "10px",
          margin: "0 auto",
          width: { md: "90%" },
          mt: { xs: "24px", sm: " 55px", md: "5.79vw" },
        }}
      >
        <Typography
          fontWeight={"500"}
          variant="h4"
          // lineHeight={"49px"}
          // letterSpacing={"0.01em"}
          textAlign={"center"}
          margin={"0 auto"}
          mb={{ xs: "41px", md: "3.01vw" }}
          display={{ xs: "none", md: "block" }}
        >
          Other Financial Services by Blacbox
        </Typography>
        <Stack
          // gap={"15px"}
          rowGap={{ xs: "24px", sm: " 28px", md: "2.89vw" }}
          columnGap={{ sm: "24px", md: "2.89vw" }}
          flexWrap="wrap"
          direction={{ xs: "column", sm: "row" }}
          sx={{
            display: { sm: "flex" },
            justifyContent: { sm: "center", md: "space-between" },
            margin: "0 auto",
          }}
        >
          <MediaCard
            text={
              "Utilize the subscription APIs provided by Blacbox to perform transactions on your portal."
            }
            title={"Subscriptions"}
            color={"#000000"}
            hoverColor={"#E2C703"}
            width={{ sm: "43%", md: "26.62vw" }}
            height={{ xs: "164px", sm: " 196px", md: "20.49vw" }}
          />
          <MediaCard
            text={
              "Employ our automated models to generate payment links for a flexible payment cycle."
            }
            title={"Payment Links"}
            color={"#000000"}
            hoverColor={"#E2C703"}
            width={{ sm: "43%", md: "26.62vw" }}
            height={{ xs: "164px", sm: " 196px", md: "20.49vw" }}
            hover
          />
          <MediaCard
            text={
              "Develop personified invoices and memos to help your business users with payment infrastructure."
            }
            color={"#000000"}
            hoverColor={"#E2C703"}
            title={"Create and Send Invoices"}
            width={{ sm: "43%", md: "26.62vw" }}
            height={{ xs: "164px", sm: " 196px", md: "20.49vw" }}
          />
        </Stack>
      </Box>
    </Box>
  );
}
