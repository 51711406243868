import React from "react";
import {
  Grid,
  Stack,
  Card,
  Box,
} from "@mui/material";
import "../style/css/waitlist/waitlistform.css";
import icon from "../images/signin/icon.png";
import { Link } from "react-router-dom";
import WaitForm from "../component/ui/waitlist/waitForm";

export default function Waitlistform() {

  return (
    <>
      <Grid container px={{ xs: "11px", sm: "18px", md: "1.91vw" }}>
        <Grid item md={12} xs={12}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            px={{ xs: "11px", sm: "18px", md: "1.91vw" }}
            sx={{
              width: "100%",
              // maxWidth:{xs:"51px", sm:"82px", md:"8.56vw"},
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "51px", sm: "82px", md: "8.56vw" },
                width: "100%",
              }}
            >
              <Link to="/">
                <img src={icon} alt="icon" width={"100%"} />
              </Link>
            </Box>
            <Box
              sx={{
                fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
                marginRight: { xs: "80px", sm: "128px", md: "13.31vw" },
              }}
            >
              <Link to="/" style={{ color: "#E2C703", fontSize: "inherit" }}>
                Close
              </Link>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Card
            sx={{
              boxShadow: "none",
              mx: "auto",
              width: { md: "100%", xs: "100%", sm: "100%" },
              backgroundColor: "black",
              mt: { xs: "28px", sm: "45px", md: "4.69vw" },
              maxWidth: { xs: "412px", sm: "658px", md: "68.63vw" },
              minHeight: { xs: "251px", sm: "400px", md: "41.78vw" },
              borderRadius: { xs: "3px", sm: "6px", md: "0.58vw" },
              marginBottom: { xs: "35px", sm: "57px", md: "5.90vw" },
              paddingBottom: { xs: "38px", sm: "60px", md: "6.25vw" },
            }}
          > <WaitForm   />;
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
