import NavBar from "../component/layout/nav";
import { Box, Typography, Grid, Button } from "@mui/material";
import SupportPageApp from "../component/layout/supportappbar";
import nav from "../images/support/nav.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
// import backgroundsupport from "../images/support/backgroundsupport.png";
import faqbaimage from "../images/faqimages/faqbaimage.png";
import { IoMdAdd } from "react-icons/io";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const accordionFaq = [
  {
    id: "01",
    title: "What is BlacBox? ",
    content:
      "BlacBox is a multi-API system that powers all services any business needs to help your business grow faster. ",
  },
  {
    id: "02",
    title: "What are the services BlacBox has? ",
    content:
      "KYC verification, payments, Bank Transfer, OTP services, Bill Payment, Virtual account number, Debit cards, etc ",
  },
  {
    id: "03",
    title: "How does a user or company sign up for BlacBox?",
    content:
      "Signing  up for BlacBox is very simple, simply click the signup button to access the form page where you will enter all your information to begin using our services. ",
  },
  {
    id: "04",
    title: "What steps do you take to make a complaint?",
    content:
      "You can reach us by phone or email, as we have a dedicated support apartment available 24/7 to assist you. ",
  },
  {
    id: "05",
    title: "What are the payment methods available on BlacBox? ",
    content:
      "Individuals and businesses can pay any service they use using  a Transfer, Payment code, QR code,or direct online payment with a debit or credit card.  ",
  },
  {
    id: "06",
    title: "What is the BlacBox waitlist, and how do you get on it? ",
    content:
      "The BlacBox waitlist is a channel through which individuals and companies can stay up to date  on the latest advancements in services and other APIs.",
  },
  {
    id: "07",
    title: "Is there documentation for BlacBox that developers can use? ",
    content:
      "Yes, there is. We have comperhensive step-by-step documentaton available for developers and anyone esle. ",
  },
  {
    id: "08",
    title: "What are the onboarding requirements for using BlacBox? ",
    content:
      "To get started on BlacBox, you must fill out the background information form, which includes Id confirmation, businesss documents, and so on.  ",
  },
  {
    id: "09",
    title: "How do you set up BlacBox for my business? ",
    content:
      "When  you sign up for BlacBox, you will see all the services that BlacBox provides, and you will be able to select or call only the endpoints that your company needs",
  },
  {
    id: "10",
    title: "What is the charge rate per service? ",
    content:
      "BlacBox charges a very reasonable fee per services, but the few varies depending on the services you will be using regulary.",
  },
];

export default function FagPage() {
  const [expanded, setExpanded] = useState(null);
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${nav})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // height: { xs: "23 0px", sm: "230px", md: "289px" },
        }}
      >
        <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
          <NavBar light />
        </Box>
        <Box
          sx={{
            mt: { xs: "23px", sm: "37px", md: "3.82vw" },
            pb: { xs: "40px", sm: "63px", md: "6.60vw" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              // fontFamily: "Montserrat",
              fontWeight: 600,
              // fontSize: "50px",
              // lineHeight: "61px",
              textAlign: "center",
              color: "#FFFFFF",
              // mt:{xs:"22px", sm:"34px", md:"49px"},
              // mb:{xs:"39px", sm:"62px", md:"88px"},
            }}
          >
            FAQ
          </Typography>
        </Box>
      </Box>
      <Box display={{xs:"none",sm:'block'}}>
        <SupportPageApp />
      </Box>

      <Box
        sx={{
          backgroundImage: { xs: "", sm: `url(${faqbaimage})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 800px",
          mt: { xs: "3px", sm: "4px", md: "0.46vw" },

          // width:"880px"
          // mt:"-10px"
        }}
      >
        <Box
          width="100%"
          mx="auto"
          // px={{ xs: "0px", sm: "0" }}
          maxWidth={{ xs: "408px", sm: "651px", md: "67.94vw" }}
          marginTop={{ xs: "11px", sm: "18px", md: "1.85vw" }}
        >
          {accordionFaq.map((item, index) => {
            const isActive = expanded === index;
            return (
              <Accordion
                expanded={isActive}
                elevation={0}
                key={index}
                onChange={() => setExpanded(isActive ? null : index)}
                sx={{
                  backgroundColor: !isActive ? "#FDFBFB" : "#FEFBE7",
                }}
              >
                <AccordionSummary
                  sx={{ fontSize: { xs: "20px", sm: "14px", md: "1.50vw" } }}
                  expandIcon={
                    !isActive ? (
                      <IoMdAdd />
                    ) : (
                      <FaTimesCircle
                        fontSize={{ xs: "20px", sm: "14px", md: "1.50vw" }}
                      />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid
                    sx={{
                      display: "flex",
                      my: isActive ? "0" : "15px",
                      px: { xs: "13px", sm: "39px", md: "4.05vw" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        marginRight: "15px",
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        // fontSize: { xs: "17px", sm: "27px", md: "29px" },
                        // lineHeight: "20px",
                        color: "#8E8E8E",
                      }}
                    >
                      {item.id}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        // fontSize: { sm: "13px", md: "19px" },
                        // lineHeight: "120%",
                        mx: { xs: "11px", sm: "18px", md: "1.91vw" },
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "left",
                      maxWidth: { xs: "243px", sm: "388px", md: "40.51vw" },
                      heght: "auto",
                      fontWeight: "400",
                      // fontSize: "16px",
                      // lineHeight: "30px",
                      color: "#4B4B4B",
                      ml: { xs: "57px", sm: "91px", md: "9.55vw" },
                      // backgroundColor:"red"
                    }}
                  >
                    {" "}
                    {item.content}{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <Box
          mx="auto"
          sx={{
            mt: { xs: "25px", sm: "40px", md: "56px" },
            textAlign: "center",
            mb: { xs: "20px", sm: "32px", md: "46px" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: { xs: "13px", sm: "9px", md: "0.93vw" },
              lineHeight: "120%",
              textAlign: "center",
              color: "#111111",
            }}
          >
            Can't find an answer to your question?
          </Typography>
          <Link to={"/contact"} style={{ textDecoration: "none" }}>
            <Button
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
                alignItems:"center",
                textAlign: "center",
                color: "#000000",
                px: "20px",

                //   spacing: "20px",
              }}
              endIcon={
                <Box
                // display="inline-block"
                height="fit-content"
                  fontSize={{ xs: "10px", sm: "17px", md: "1.74vw" }}
                  variant="subtitle1"
                  sx={
                    {
                      // marginLeft: {xs:"7px", sm:"12px", md:"1.22vw"},
                      //   px: "20px",
                    }
                  }
                >
                  <BsArrowRightCircleFill
                    fontSize="inherit"
                    // fontSize={{xs:"19px", sm:"13px", md:"1.39vw"}}
                  />
                </Box>
              }
            >
              Submit a request
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
