import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './layout/main';
import HomePage from './pages/home';
import ServicePage from './pages/service';
import AboutPage from './pages/about';
import Loginpage from './pages/signin';
import Signuppage from './pages/signup';
import ContactPage from './pages/contact';
import PrincingPage from './pages/pricing/index'
import Customerpage from './pages/customer';
import Enterprisepage from './pages/enterprise';
import Waitlist from './pages/waitlist';
import PartnersPage from './pages/partners';
import Waitlistform from './pages/waitlistForm';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import AppTheme from './style/theme/main';
import SupportPage from './pages/support';
import FaqPage from './pages/faq';
import JobPage from './pages/jobpage';
import JobSecondPage from './pages/jobsecondpage';
import JobThirdPage from './pages/jobthirdpage';




// import "@fontsource/poppins/"
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

import DocumentationPage from './pages/documentation';
// import JobThirdPage from './pages/jobthirdpage';
import UnitPage from './pages/unit';



function App() {
  return (
    <ThemeProvider theme={createTheme(AppTheme)}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path='service' element={<ServicePage />} />
            <Route path='about' element={<AboutPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/doc' element={<DocumentationPage />} />
            <Route path='/pricing' element={<PrincingPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/customer' element={<Customerpage />} />
            <Route path='/enterprise' element={<Enterprisepage />} />
            <Route path='/doc' element={<DocumentationPage />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/jobs/apply' element={<JobSecondPage />} />
            <Route path='/jobs/form' element={<JobThirdPage />} />
            <Route path='/support' element={<SupportPage />} />
            <Route path='/partners' element={<PartnersPage />} />
            <Route path='/jobs' element={<JobPage />} />
          </Route>
          <Route path='/login' element={<Loginpage />} />
          <Route path='/signup' element={<Signuppage />} />
          <Route path='/waitlist' element={<Waitlist />} />
          <Route path='/waitlist/form' element={<Waitlistform />} />
          <Route path='/unit' element={<UnitPage />} />
        </Routes>
      </BrowserRouter>

    </ThemeProvider>);
}

export default App;
