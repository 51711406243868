import {
  // Avatar,
  Box,
  // Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { Link } from "react-router-dom";
import AppTheme from "../style/theme/main";

import { FiTrendingUp } from "react-icons/fi";
import { MdPayments, MdControlCamera } from "react-icons/md";

import heroImage from "../images/home/heroFull.svg";
import heroImageMobile from "../images/home/heroFullMobile.svg";
import handIcon from "../images/home/hand_play_icon.svg";
import benefitsImage from "../images/home/home_benefits.svg";
import serviceImage from "../images/home/home_services.svg";
import apiImage from "../images/home/home_api.svg";
import expandImage02 from "../images/home/expand02.svg";
import expandImage03 from "../images/home/expand03.svg";

import ideatLogo from "../images/home/ideat_logo.svg";
import mcLogo from "../images/home/mc_logo.svg";
import mxLogo from "../images/home/mx_logo.svg";
import NavBar from "../component/layout/nav";
import StyleAccordion from "../component/ui/styleAccordion";
import CustomButton from "../component/ui/customButton";
import { useState } from "react";

const benefits = [
  {
    img: handIcon,
    title: "Reliable Integrations",
    text: "Our technology platform will help you aggregate and integrate into a wide range of digital products and services. It will aid in the enablement and swift access to financial services.",
  },
  {
    icon: FiTrendingUp,
    title: "Multiple services, one API",
    text: "Competitive pricing models;  An API platform to help African businesses build better solutions easily, quickly, and at an affordable cost.  ",
  },
  {
    icon: MdPayments,
    title: "Competitive pricing",
    text: "An API platform to help African businesses build better solutions easily, quickly, and at an affordable cost.  ",
  },
  {
    icon: MdControlCamera,
    title: "High availability",
    text: "We are always prepared to provide you with an assimilation and API solution using the most cutting-edge technology.",
  },
  {
    icon: MdControlCamera,
    title: "Dedicated Customer Support",
    text: "To provide users with speed, convenience, and confidence, we simplify the purchasing process for the final customer. Making it simple to ask for feedback and help.",
  },
];

const testimonials = [
  {
    img: mxLogo,
    title: "Merchant: WallX",
    text: "Utilizing Blacbox API implementations allowed us to create friendly and simple financial processes for our service users. This is one integration provider that offers a straightforward and thoroughly explained procedure for companies to use.",
  },
  {
    img: mcLogo,
    title: "Merchant: Momo Ltd",
    text: "We powered our lending site, framework, and implementation with Blacbox APIs and services. They offer a frictionless B2B automation process that makes integration simple and easy.",
  },
  {
    img: ideatLogo,
    title: "Merchant: Ideate Zone",
    text: "Blacbox's user-friendly and comprehensive API methods gave us the freedom to easily solve every assimilation problem needed to run our business",
  },
];

const faq = [
  {
    title: "KYC Verification",
    text: "We will help you in implementing KYC processing by leveraging our best integration tools.",
    image: apiImage,
  },
  {
    title: "Payments and Wallet API’s",
    text: "Manage basic payment and wallet functions such as performing a simple token transfer and scaling payment transactions smoothly.",
    image: expandImage02,
  },
  {
    title: "OTP’s Notification Services",
    text: "Get to build a smooth OTP notification process for your business to protect customer data.",
    image: expandImage03,
  },
];

export default function HomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("ta"));

  const [activeAccordion, setActiveAccordion] = useState(0);

  return (
    <Stack>
      <Box>
        <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
          <NavBar />
        </Box>
        <Stack
          direction={{ xs: "column", ta: "row" }}
          justifyContent={"space-between"}
        >
          <Box
            ml={{ xs: "24px", sm: "55px", md: "5.79vw" }}
            width="100%"
            maxWidth={{ xs: "79vw", ta: "31.48vw" }}
          >
            <Box mt={{ xs: "10vw", ta: "6.77vw" }}>
              <Typography
                variant="h3"
                // color={AppTheme.palette.primary.main}
                letterSpacing={""}
                lineHeight={{ xs: "145%", md: "131.02%" }}
                fontWeight="400"
                // fontSize={{ xs: "1.67em", sm: "2.89vw" }}
                maxWidth={{ xs: "79vw", ta: "31.48vw" }}
              >
                One API, Multiple Services for African Businesses.
              </Typography>
              <Typography
                mt={{ xs: "2vw", ta: "1.16vw" }}
                // fontSize={{ xs: "0.67em", md: "0.750em" }}
                variant="body2"
                // fontSize={"14px"}
                maxWidth={{ xs: "69vw", ta: "31.48vw" }}
              >
                Coherent APIs to generate new accounts, send payments, verify
                OTP and BVN, and retrive bank information.
              </Typography>
              <Box
                // maxWidth={{ xs: "144px", sm: "230px", md: "326px" }}
                // height={{ xs: "59px", md: "83px" }}
                mt={{ xs: "4vw", ta: "2.89vw" }}
              >
                <CustomButton
                  dark
                  text=" Sign Up"
                  href="https://portal.blacbox.app/register"
                  target="_blank"
                />
                {/* <Button
                  fullWidth
                  sx={{
                    // mt: { xs: "17px", sm: "27px", md: "39px" },
                    height: "100%",
                  }}
                  style={{ textDecoration: "none" }}
                  color="secondary"
                  href="https://portal.blacbox.app/register"
                  target="_blank"
                  variant="contained"
                >
                  Sign Up
                </Button> */}
              </Box>
            </Box>
          </Box>
          <Box
            alignSelf={{ xs: "flex-start", ta: "flex-end" }}
            width="100%"
            mt={{ xs: "13vw", ta: "1.39vw" }}
            ml={{ xs: "0", ta: "9.66vw" }}
            maxWidth={{ xs: "92vw", ta: "55.79vw" }}
          >
            <img
              src={isMobile ? heroImageMobile : heroImage}
              alt=""
              width={"100%"}
            />
          </Box>
        </Stack>
      </Box>

      <Box
        borderRadius={{
          xs: "18vw 0 0 0 ",
          ta: "13.37vw 0 0 0 ",
        }}
        mt={{ xs: "15vw", ta: "3.07vw" }}
        px={{ xs: "6vw", ta: "3.18vw" }}
        pt={{ xs: "15vw", ta: "4.34vw" }}
        pb={{ xs: "11vw", ta: "6.60vw" }}
        color="white"
        bgcolor={"#0D0D0D"}
      >
        <Stack direction={{ xs: "column", ta: "row" }}>
          <Box
            display={{ xs: "none", ta: "block" }}
            width="100%"
            maxWidth={{ xs: "0vw", ta: "42.65vw" }}
            mr={{ xs: "0vw", ta: "10.30vw" }}
            mt={{ xs: "0vw", ta: "17.01vw" }}
          >
            <img src={benefitsImage} width="100%" alt="benefit_image" />
          </Box>
          <Box width={"100%"} maxWidth={{ xs: "74vw", ta: "34.16vw" }}>
            <Box mb={{ xs: "7vw", ta: "4.17vw" }}>
              <Typography
                fontWeight={"400"}
                variant="h4"
                // fontSize={{ xs: "1.25em", ta: "2.31vw" }}
                textAlign="left"
                // fontFamily={"poppins"}
              >
                Why Choose us
              </Typography>
            </Box>
            <Stack spacing={{ xs: "10vw", ta: "2.31vw" }}>
              {benefits.map((item) => (
                <Box fontSize={{ xs: "1.25em", ta: "2.31vw" }} key={item.title}>
                  {item.icon ? (
                    <item.icon
                      style={{
                        fontSize: "inherit",
                        color: AppTheme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: { xs: "6vw", ta: "2.31vw" },
                      }}
                    >
                      <img src={handIcon} width="100%" alt="benefit_images" />
                    </Box>
                  )}
                  <Typography
                    mt={{ xs: "5vw", ta: "0.58vw" }}
                    variant="h6"
                    // fontSize={{ xs: "0.83em", ta: "1.39vw" }}
                    fontWeight={600}
                  >
                    {" "}
                    {item.title}{" "}
                  </Typography>
                  <Typography
                    // fontSize={{ xs: "0.67em", ta: "1.04vw" }}
                    variant="body2"
                    mt={{ xs: "2vw", ta: "0.58vw" }}
                  >
                    {" "}
                    {item.text}{" "}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Stack
        width="100%"
        direction={{ xs: "column", ta: "row" }}
        justifyContent="space-between"
        px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
        alignItems={{ xs: "flex-start", ta: "center" }}
        // spacing={{xs:"48px", sm:"77px", md:"110px"}}
      >
        <Box
          width="100%"
          // ml={{ xs: "6vw", ta: "4.40vw" }}
          // mr={{ xs: "7vw", ta: "3.18vw" }}
          mt={{ xs: "14.80vw", ta: "0.00vw" }}
          maxWidth={{ xs: "87vw", ta: "40.8vw" }}
        >
          <Stack>
            <Typography
              variant="h3"
              fontWeight={"442"}
              // fontSize={{ xs: "1.25em", ta: "2.89vw" }}
              maxWidth={{ md: "33.68vw" }}
              lineHeight={{ xs: "145%", md: "131.02%" }}

              // letterSpacing={{ xs: "1px", sm: "2px", md: "3px" }}
            >
              Integrating products with the features that customers need.{" "}
            </Typography>
            <Box
              // fontSize={{ xs: "0.67em", ta: "1.04vw" }}
              variant="body2"
              mt={{ xs: "5vw", ta: "1.74vw" }}
              mb={{ xs: "6vw", ta: "2.89vw" }}
            >
              <Typography>
                {" "}
                Blacbox provides automated solutions to help you create digital
                products that meet customer needs. We will remove the burden of
                your business and marketing, allowing you to focus on what
                matters most.
              </Typography>
              <Typography
                fontWeight={"600"}
                color={AppTheme.palette.primary.main}
              >
                {" "}
                Growing your business.
              </Typography>
            </Box>
            <CustomButton to="/service" text="Explore services" />
          </Stack>
        </Box>
        <Box
          mt={{ xs: "14vw", ta: "5.21vw" }}
          ml={{ xs: "11vw", ta: "0.00vw" }}
          mr={{ xs: "9vw", ta: "0.00vw" }}
          width="100%"
          maxWidth={{ xs: "80vw", ta: "44.62vw" }}
        >
          <img src={serviceImage} alt="service_image" width={"100%"} />
        </Box>
      </Stack>

      <Box mt={{ xs: "20vw", ta: "6.60vw" }} px={{ xs: "6vw", ta: "5.79vw" }}>
        <Box textAlign={{ xs: "left", ta: "center" }}>
          <Typography fontSize={{ xs: "1.00em", ta: "1.74vw" }} color="#4B4B4B">
            Our Focus
          </Typography>
          <Typography
            variant="h3"
            fontWeight={"600"}
            // fontSize={{ xs: "1.25em", ta: "2.31vw" }}
            mt={{ xs: "2vw", ta: "0.58vw" }}
          >
            A System With A Seamless Integrated API
          </Typography>
        </Box>

        <Stack
          mt={{ xs: "11vw", ta: "4.28vw" }}
          direction={{ xs: "column", ta: "row" }}
          justifyContent="space-between"
          columnGap={{ ta: "6.19vw" }}
          rowGap={{ xs: "6vw" }}
        >
          <Box width="100%" maxWidth={{ xs: "90vw", ta: "48.78vw" }}>
            <StyleAccordion
              setActive={setActiveAccordion}
              defaultIndex={0}
              data={faq}
              activeColor="#E2C703"
            />
          </Box>
          <Box width="100%" display={{xs:"none",ta:"block"}} maxWidth={{ xs: "90vw", ta: "33.45vw" }}>
            <img
              src={faq[activeAccordion]?.image || apiImage}
              width="100%"
              alt="home_api_image"
            />
          </Box>
        </Stack>
      </Box>

      <Box
        px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
        mt={{ xs: "20vw", ta: "4.40vw" }}
        mb={{ xs: "18vw", ta: "7.00vw" }}
      >
        <Box>
          <Typography
            // fontSize={{ xs: "1.25em", ta: "2.31vw" }}
            variant="h4"
            textAlign={"center"}
            fontWeight={"600"}
          >
            {" "}
            Testimonials
          </Typography>
        </Box>
        <Stack
          direction={{ xs: "column", ta: "row" }}
          justifyContent="space-between"
          columnGap={{ xs: "0vw", ta: "2.03vw" }}
          rowGap={{ xs: "10vw", ta: "0.00vw" }}
          mt={{ xs: "10vw", ta: "2.89vw" }}
        >
          {testimonials.map((item) => (
            <Box
              key={item.title}
              width={"100%"}
              // bgcolor="#F9F9F9"
              maxWidth={{ ta: "30.21vw" }}
              // minHeight={{ xs: "80vw", ta: "28.65vw" }}
              px={{ xs: "6vw", ta: "1.16vw" }}
              boxShadow={{
                xs: "0.20vw 1.20vw 0 rgba(0, 0, 0, 0.1)",
                ta: "0.06vw 0.35vw 0 rgba(0, 0, 0, 0.1)",
              }}
              // borderColor={"#DDD3D3"}
              // sx={{ ":hover": { borderColor: "#E2C703 #DDD3D3" } }}
            >
              <Box
                boxShadow={{
                  xs: "0 0 0",
                  ta: "0px -0.12vw 0.35vw 0.12vw rgba(0, 0, 0, 0.15)",
                }}
                sx={{ ":hover": { boxShadow: "0 0 0" } }}
                width={"100%"}
                maxWidth={{ md: "25.64vw" }}
                px={{ xs: "2.00vw", ta: "0.58vw" }}
                py={{ xs: "2.00vw", ta: "1.74vw" }}
              >
                <Typography
                  variant="h6"
                  textAlign={"center"}
                  fontSize={{ xs: "0.75em", ta: "1.39vw" }}
                  fontWeight={"600"}
                >
                  {item.title}
                </Typography>
              </Box>
              <Box
                mt={{ xs: "9.00vw", ta: "4.05vw" }}
                mb={{ xs: "11.60vw", ta: "9.26vw" }}
                textAlign="center"
              >
                <Box
                  height={{ xs: "12.00vw", ta: "4.63vw" }}
                  textAlign="center"
                  mx="auto"
                >
                  <img src={item.img} alt={item.title + "logo"} height="100%" />
                </Box>
                <Box mt={{ xs: "6.00vw", ta: "2.31vw" }}>
                  <Typography
                    // fontSize={{ xs: "0.58em", ta: "1.04vw" }}
                    variant="body2"
                    letterSpacing="0.05em"
                    // textAlign={}
                  >
                    {" "}
                    {item.text}{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}
