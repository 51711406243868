import { Box, Typography, Grid, Stack, useMediaQuery } from "@mui/material";
import NavBar from "../component/layout/nav";
import hands from "../images/partners/hands.png";

import * as React from "react";

import firstbaseImage from "../images/partners/firstbase.svg";
import sendgridImage from "../images/partners/sendgrid.svg";
import bondImage from "../images/partners/bond.svg";
import flutterImage from "../images/partners/brandFlutterWave.svg";
import mecuryImage from "../images/partners/brandMecury.svg";
import royalImage from "../images/partners/brandRoyal.svg";
import twilloImage from "../images/partners/brandTwillo.svg";
import stripeImage from "../images/partners/stripe.svg";
// import bondImage from "../images/partners/bond.svg"

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { useTheme } from "@emotion/react";

import lighticonImage from "../images/partners/lighticon.svg";
import streamlineImage from "../images/partners/streamline.svg";
import expandImage from "../images/partners/expand.svg";

import bgImage from "../images/partners/bg.svg";
import HelpCenter from "../component/ui/customer/helpCenter";
import ListSlide from "../component/ui/listSlide";
import CustomButton from "../component/ui/customButton";

export default function PartnersPage() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const developments = [
    {
      image: lighticonImage,
      title: "Driven innovation",
      text: "We push innovative ideas that have potential and solutions for businesses and users through partnerships.",
    },
    {
      image: streamlineImage,
      title: "Streamline operations",
      text: "We run an automated  operating system that partners can easily leverage.",
    },
    {
      image: expandImage,
      title: "Expand globally",
      text: "Creating national and international alliances that will thrive in the coming years.",
    },
  ];

  const partners = [
    firstbaseImage,
    sendgridImage,
    stripeImage,
    bondImage,
    flutterImage,
    mecuryImage,
    royalImage,
    twilloImage,
  ];

  return (
    <Box>
      {/* PART 1 */}
      <Box
        bgcolor="#272727"
        sx={{
          backgroundImage: `url(${hands})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        color="#111111"
      >
        <Box bgcolor={"rgba(226, 199, 3, 0.53)"}>
          <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
            <NavBar dark />
          </Box>
          <Box
            width="100%"
            sx={{ textAlign: { xs: "left", md: "center" }, margin: "auto" }}
            maxWidth={{ xs: "288px", sm: "460px", md: "47.97vw" }}
            mx={{ xs: "24px", md: "auto" }}
            bgcolor="rgba()"
            pt={{ xs: "37px", md: "7.06vw" }}
            pb={{ xs: "53px", sm: "84px", md: "8.68vw" }}
          >
            <Typography
              // fontSize={{ xs: "31px", md: "2.89vw" }}
              variant="h3"
              fontWeight={"500"}
            >
              Let Us Work Together to Secure Africa's Payment Future
            </Typography>
            <Typography
              // fontSize={{ xs: "10px", sm: "10px", md: "14px" }}
              fontWeight={"400"}
              variant="body2"
              maxWidth={{ md: "35.24vw" }}
              mx={{ xs: 0, md: "auto" }}
              mt={{ xs: "16px", md: "1.16vw" }}
              // sx={{ textAlign: "center", margin: "auto" }}
            >
              We value collaboration and leverage it to build better API
              modeling services that can assist businesses to take structure.
            </Typography>
            <Box mt={{ xs: "24px", md: "2.31vw" }}>
              <CustomButton
                textProps={{
                  // fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
                }}
                dark
                text="Become A Partner"
                href="https://portal.blacbox.app/register"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* PART 2 */}
      <Box
        mt={{ xs: "39px", sm: "55px", md: "5.79vw" }}
        sx={{ textAlign: "center" }}
      >
        <Typography px="24px" variant="h3" fontWeight={"467"}>
          {" "}
          We Partner With Outstanding Enterprises{" "}
        </Typography>
        <Box
          pz={{ xs: "10px", md: "0.75vw" }}
          // pr={{ xs: "23px", sm: "37px", md: "53px" }}
        >
          <Stack
            // direction={{ xs: "row", sm: "row", md: "row" }}
            // sx={{ gap: "20px" }}
            mt={{ xs: "23px", md: "2.31vw" }}
            className="slide-container"
          >
            <Slide
              arrows={false}
              slidesToShow={isMobile ? 1 : isTablet ? 3 : 4}
            >
              {partners.map((item, index) => (
                <Box
                  className="each-slide"
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      background: "#F6F6F6",
                      height: { sm: "78px", md: "8.33vw" },
                      width:"100%",
                      // width: { xs: "132px", sm: "211px", md: "22.45vw" },
                      mx: { xs: "17px", sm: "28px", md: "2.89vw" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    key={index}
                  >
                    <img src={item} alt="kyc_image" width="70%" />
                  </Box>
                </Box>
              ))}
            </Slide>
          </Stack>
        </Box>
      </Box>

      {/* PART 3 */}

      <Box
        mt={{ xs: "39px", md: "9.61vw" }}
        px={{ xs: "39px", sm: "55px", md: "5.79vw" }}
      >
        <Typography
          variant="h3"
          fontSize={{ xs: "19px", md: "1.74vw" }}
          width="100%"
          fontWeight={{ xs: "500", sm: "600" }}
          textAlign={{ sx: "left", xs: "center", md: "left" }}
          maxWidth={{ xs: "84.80vw", md: "39.08vw" }}
        >
          We work with partners who can enable businesses at every stage of
          development
        </Typography>
        <Box
          // px={{xs:"24px",sm:" 55px",md:"5.79vw"}}
          mt={{ xs: "33px", md: "2.89vw" }}
        >
          <Grid
            container
            justifyContent={{ xs: "center", md: "flex-start" }}
            columnSpacing={{ xs: "24px", sm: " 28px", md: "2.89vw" }}
            rowSpacing={{ xs: "24px", sm: " 28px", md: "2.89vw" }}
          >
            {developments.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={item.title}>
                <Box
                  py={{ xs: "28px", sm: " 33px", md: "3.41vw" }}
                  px={{ xs: "24px", md: "1.39vw" }}
                  pb={{ xs: "47px", sm: " 73px", md: "7.64vw" }}
                  height="100%"
                  sx={{
                    width: "100%",
                    // maxWidth: { md: "386px" },
                    bgcolor: "#F6F6F6",
                    borderRadius: { xs: "8px", md: "0.58vw" },
                    color: "#000000",
                    ":hover": {
                      bgcolor: "#E2C703",
                    },
                  }}
                >
                  <Stack
                  // justifyContent={"space-between"}
                  // minHeight={{ xs: "214px", sm: " 240px", md: "25.00vw" }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <img src={item.image} alt="kyc_image" width="20%" />
                    </Box>
                    <Box
                      width={"100%"}
                      mt={{ xs: "24px", sm: " 29px", md: "3.01vw" }}
                    >
                      <Typography
                        sx={{ textAlign: "center" }}
                        // fontSize={{ xs: "19px", md: "1.74vw" }}
                        variant="h5"
                        fontWeight={"800"}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        // fontSize={{ xs: "11px", md: "1.04vw" }}
                        variant="body2"
                        sx={{ textAlign: "center" }}
                        mt={{ xs: "8px", sm: " 11px", md: "1.16vw" }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* PART 4 */}

      <Box
        mt={{ xs: "79px", md: "5.79vw" }}
        pl={{ xs: "24px", md: "11.00vw" }}
        pr={{ xs: "24px", md: "5.79vw" }}
      >
        <Stack direction={"row"} alignItems="center" spacing={2}>
          <Typography
            sx={{ display: "flex" }}
            fontSize={{ xs: "13px", md: "0.93vw" }}
            fontWeight={"400"}
          >
            Our Partners{" "}
          </Typography>

          <Box
            width={{ xs: "57px", md: "4.22vw" }}
            height="2px"
            bgcolor="#222831"
          ></Box>
        </Stack>
        <Typography
          mt={{ xs: "8px", md: "0.46vw" }}
          variant="h4"
          fontWeight={"500"}
          maxWidth={{xs:"75.00vw", sm:"55vw", md:"38.83vw"}}
        >
          Find a partner with the solution or services you need
        </Typography>
        <Box mt={{ xs: "31px", md: "1.39vw" }}>
          <ListSlide />
        </Box>
      </Box>

      {/* PART 5 */}
      <Box
        mt={{ md: "-5.21vw" }}
        pt={{ md: "5.21vw" }}
        px={"23px"}
        sx={{
          backgroundImage: { xs: "", md: `url(${bgImage})`, sm: "" },
          backgroundSize: "19.62vw",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <Box
          mt={{ xs: "47px", sm: " 83px", md: "8.68vw" }}
          mb={{ xs: "44px", sm: " 47px", md: "4.92vw" }}
        >
          <HelpCenter />
        </Box>
      </Box>
    </Box>
  );
}
