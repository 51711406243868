import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

// import apiImage from "../../images/home/home_api.svg";

export default function StyleAccordion({
  data = [],
  titleProps = {},
  textProps = {},
  activeColor = "",
  defaultIndex = null,
  setActive,
  sx = {},
  ...props
}) {
  const [expanded, setExpanded] = useState(defaultIndex);

  const updateExpanded = (index) => {
    const isActive = expanded === index;
    setExpanded(isActive ? null : index);
    setActive && setActive(index);
  };
  return (
    <>
      {data.map((item, index) => {
        const isActive = expanded === index;
        return (
          <>
            <Accordion
              expanded={isActive}
              elevation={0}
              key={index}
              onChange={() => updateExpanded(index)}
              sx={{
                backgroundColor: !isActive
                  ? "#FDFBFB"
                  : activeColor || "#FEFBE7",
                borderRadius: "10px",
                ...sx,
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: { xs: "19px", md: "1.39vw" },
                }}
                expandIcon={
                  !isActive ? (
                    <IoMdAdd />
                  ) : (
                    <FaTimesCircle fontSize={"inherit"} />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  pl={{ xs: "18px", sm: "38px", md: "4.05vw" }}
                  sx={{
                    display: "flex",
                    py: isActive
                      ? "0"
                      : { xs: "17px", sm: "31px", md: "1.10vw" },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      marginRight: { xs: "15px", sm: "24px", md: "2.49vw" },
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      color: "#8E8E8E",
                      ...titleProps,
                    }}
                  >
                    {index < 9 ? 0 : ""}
                    {index + 1}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      // fontSize: { sm: "13px", md: "19px" },
                      lineHeight: "120%",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  pl={{ xs: "57px", sm: "91px", md: "9.55vw" }}
                  // pl={{xs:"18px", sm:"38px", md:"54px"}}
                >
                  <Typography
                    variantt="subtitle1"
                    sx={{
                      textAlign: "left",
                      color: "#4B4B4B",
                      ...textProps,
                      // backgroundColor:"red"
                    }}
                  >
                    {item.text}{" "}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            {item.image ? (
              <Box
                display={{
                  xs: expanded != null && isActive ? "block" : "none",
                  ta: "none",
                }}
              >
                <img src={item.image} alt=";" width="100%" />
              </Box>
            ) : null}
          </>
        );
      })}
    </>
  );
}
