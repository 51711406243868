import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    transform: `translateY(${-value}%) !important`,
    backgroundColor: "black",
  },
}));

export default function VerticalBar({value,total}) {
  return (
    <Stack
      mt={{ xs: "0.00vw", ta: "1.66vw" }}
      alignItems={"center"}
      rowGap={{ xs: "0.00vw", ta: "1.39vw" }}
      display={{ xs: "none", ta: "flex" }}
    >
      <BorderLinearProgress
        sx={{
          height: { xs: "80%", md: "9.26vw" },
          width: { xs: "0.23vw", ta: "0.23vw" },
        }}
        variant="determinate"
        value={((total -1 - value) / total) * 100}
      />
      <Box
        sx={{
          transform: "rotate(-90deg) translateX(-50%)",
        }}
      >
        <Typography fontSize={{ xs: "0.00em", ta: "0.75em" }}>
          0{value + 1}/<span style={{ color: "#9A9A9A" }}>0{total} </span>
        </Typography>
      </Box>
    </Stack>
  );
}
