import { Typography, Link as MuiLink, Button } from "@mui/material";
import { useTheme } from "@mui/system";
import { Link } from "react-router-dom";

const LinkComponent = ({ children, to, href, target }) => {
  return to ? (
    <Link to={to}>{children}</Link>
  ) : (
    <MuiLink href={href} target={target}>
      {children}
    </MuiLink>
  );
};

export default function CustomButton({
  text = "Get Started",
  sx = {},
  target = "_blank",
  dark,
  light,
  gradient,
  href = "",
  to = "",
  textProps = {},
  iconProps = {},
  ...props
}) {
  const theme = useTheme();

  const bgcolor = dark ? "black" : light ? "white" : theme.palette.primary.main;
  const color = dark ? "white" : "black";

  return (
    <LinkComponent to={to} href={href} target={target}>
      <Button
        disableElevation
        variant={light ? "outlined" : "contained"}
        className={gradient ? "button" : ""}
        sx={{
          borderRadius: { xs: "8px", md: "0.58vw" },
          borderColor: "black",
          bgcolor: bgcolor,
          display: "inline-block",
          color: color,
          px: { xs: "16px", md: "1.16vw" },
          width: "100%",
          minHeight: { xs: "50px", sm: "60px", md: "6.25vw" },
          minWidth:{xs:"40.00vw",sm:"fit-content"},
          maxWidth: { xs: "fit-content", sm:"40.00vw",  md: "24.42vw" },
          ":hover": {
            bgcolor: bgcolor,
            color: color,
          },
          ...sx,
        }}
        {...props}
      >
        <Typography variant="h5" noWrap fontWeight="600" {...textProps}>
          {text}
        </Typography>
      </Button>
    </LinkComponent>
  );
}
