import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import NavBar from "../component/layout/nav";

import { FaArrowCircleRight } from "react-icons/fa";

import heroBg from "../images/service/service_hero.svg";
import kycImage from "../images/service/service_kyc.svg";
import otpImage from "../images/service/service_otp.svg";
import cacImage from "../images/service/service_cac.svg";
import serviceImage from "../images/service/services.svg";
import CustomButton from "../component/ui/customButton";
// import { NavLink } from "react-router-dom"

const bankServices = [
  {
    title: "Banking for Agencies",
    text: "We will provide flawless business involvement on your behalf, as well as all banking services required by your customers.",
  },
  {
    title: "Bank Transfers",
    text: "Use our computer-controlled unification and API to control a streamlined transfer process and ensure your customers complete transfers without dispute.",
  },
  {
    title: "Recurring Payments",
    text: "We create an integration model that will enable your company to include a scheme wherein your patrons can authorize the automatic withdrawals from their accounts.",
  },
  {
    title: "Verification of Bank Statements",
    text: "Help with the proofing of bank records and details.",
  },
];

const services = [
  {
    title: "Bill Payment",
    text: "Bill payment is made simple with a virtual account mechanism to help your company and its customers thrive.",
  },
  {
    title: "Debit Cards",
    text: "Create virtual cards that can be used anywhere in the world. Develop, initiate, and maintain your cards from any place.",
  },
  {
    title: "Virtual Accounts",
    text: "Generating of virtual abandoned accounts for your customers.",
  },
  {
    title: "Phone Number Verification",
    text: "Check who is behind a phone number as part of your KYC processes.",
  },
];

export default function ServicePage() {
  return (
    <Box>
      <Box
        bgcolor="#272727"
        sx={{
          backgroundImage: { xs: "", md: `url(${heroBg})` },
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "44.39vw",
          // backgroundSize: "auto 100%",
        }}
        color="white"
      >
        <Box bgcolor={"rgba(17, 17, 17, 0.38)"}>
          <Box px={{ xs: "6.00vw", ta: "5.79vw" }}>
            <NavBar light />
          </Box>
          <Box
            width="100%"
            maxWidth={{ xs: "72.40vw", ta: "47.86vw" }}
            pl={{ xs: "6.00vw", ta: "5.79vw" }}
            // pr={{ xs: "38px", sm: "0px" }}
            bgcolor="rgba()"
            // pt={{ xs: "67px", sm: "107px", md: "151px" }}
            // pt={{xs:"37px", sm:"58px", md:"83px"}}
            pt={{ xs: "12.40vw", ta: "11.34vw" }}
            pb={{ xs: "21.60vw", ta: "8.91vw" }}
          >
            <Typography
              fontSize={{ xs: "1.67em", ta: "4.05vw" }}
              fontWeight={"467"}
            >
              {" "}
              Delivering A Complete API Solution That Makes An Impact
            </Typography>
            <Box mt={{ xs: "6.00vw", ta: "2.89vw" }}>
              <CustomButton
                text="Sign Up"
                href="https://portal.blacbox.app/register"
                target="_blank"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        pl={{ xs: "6.00vw", ta: "5.79vw" }}
        pr={{ xs: "6.00vw", ta: "2.89vw" }}
        mt={{ xs: "12.40vw", ta: "4.28vw" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={{md:"center"}}
        >
          <Box
            width={"100%"}
            maxWidth={{ xs: "71.60vw", ta: "37.15vw" }}
            mr={{ xs: "0.00vw", ta: "3.41vw" }}
          >
            <Typography variant="h3" fontSize={{ xs: "1.25em", ta: "2.31vw" }}>
              {" "}
              KYC Verification{" "}
            </Typography>
            <Typography
              mt={{ xs: "2.00vw", ta: "0.58vw" }}
              variant="subtitle1"
              fontSize={{ xs: "0.67em", ta: "1.39vw" }}
              // fontSize={{ md: "19px" }}
            >
              We will help you in implementing KYC processing by leveraging our
              best integration tools.
            </Typography>
            <Box mt={{ xs: "6.00vw", ta: "1.74vw" }}>
              <CustomButton light text="Explore tools" />
            </Box>
          </Box>
          <Box
            width={"100%"}
            maxWidth={{ xs: "87.80vw", ta: "50.17vw" }}
            mt={{ xs: "6.00vw", ta: "0.00vw" }}
          >
            <img src={kycImage} alt="kyc_image" width="100%" />
          </Box>
        </Stack>
      </Box>

      <Stack
        direction="row"
        // justifyContent={"space-between"}
        alignItems="center"
        mt={{ xs: "10.00vw", ta: "5.79vw" }}
      >
        <Box
          // alignSelf={{ sm: "flex-end" }}
          width={"100%"}
          maxWidth={{ xs: "6.00vw", ta: "40.22vw" }}
          mr={{ xs: "4.00vw", ta: "9.78vw" }}
          height={{ xs: "34.40vw", ta: "8.91vw" }}
          bgcolor="#0D0D0D"
        ></Box>

        <Box
          width={"100%"}
          // pt={{ xs: "35px", sm: "55px", md: "5.79vw" }}
          // pb={{ xs: "49px", sm: "79px", md: "8.22vw" }}
          maxWidth={{ xs: "80.40vw", ta: "37.15vw" }}
          // ml={{ xs: "23px", sm: "0px" }}
          // mb={{ xs: "22px", sm: "35px", md: "49px" }}
          // mb={"10px"}
        >
          <Typography variant="h3" fontSize={{ xs: "1.25em", ta: "2.31vw" }}>
            {" "}
            Payments and Wallet API’s{" "}
          </Typography>
          <Typography
            mt={{ xs: "2.00vw", ta: "0.58vw" }}
            fontSize={{ xs: "0.67em", ta: "1.39vw" }}
            // maxWidth={{ xs: "223px", sm: "356px", md: "37.15vw" }}
            // height={{ xs: "31px", sm: "50px", md: "5.21vw" }}
          >
            {" "}
            Manage basic payment and wallet functions such as performing a
            simple token transfer and scaling payment transactions smoothly.{" "}
          </Typography>
          <Box
            mt={{ xs: "6.00vw", ta: "1.74vw" }}
            display={{ xs: "none", ta: "block" }}
          >
            <CustomButton light text="Explore tools" />
          </Box>
        </Box>
      </Stack>

      <Box
        pl={{ xs: "6.00vw", ta: "5.79vw" }}
        pr={{ xs: "6.00vw", ta: "2.89vw" }}
        mt={{ xs: "20.00vw", ta: "8.22vw" }}
      >
        <Stack
          direction={{ xs: "column", ta: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            width={"100%"}
            maxWidth={{ xs: "79.80vw", ta: "37.15vw" }}
            // mt={{ xs: "20px", sm: "33px", md: "0px" }}
            mr={{ xs: "0.00vw", ta: "3.47vw" }}
          >
            <Typography variant="h3" fontSize={{ xs: "1.25em", ta: "2.31vw" }}>
              {" "}
              OTP’s Notification Services{" "}
            </Typography>

            <Typography
              mt={{ xs: "8px", md: "0.58vw" }}
              fontSize={{ xs: "0.67em", ta: "1.39vw" }}
              // maxWidth={{ xs: "223px", sm: "356px", md: "37.15vw" }}
              // height={{ xs: "21px", sm: "33px", md: "3.47vw" }}
            >
              {" "}
              Get to build a smooth OTP notification process for your business
              to protect customer data.{" "}
            </Typography>
            <Box
              mt={{ xs: "6.00vw", ta: "1.74vw" }}
              display={{ xs: "none", ta: "block" }}
            >
              <CustomButton light text="Explore tools" />
            </Box>
          </Box>
          <Box width={"100%"} mt={{xs:"6.00vw", ta:"0.00vw"}} maxWidth={{ xs: "87.80vw", ta: "50.69vw" }}>
            <img src={otpImage} alt="kyc_image" width="100%" />
          </Box>
        </Stack>
      </Box>

      <Box
        mt={{ xs: "20.00vw", ta: "5.79vw" }}
        px={{ xs: "6.00vw", ta: "1.74vw" }}
      >
        <Box mb={{ xs: "6.00vw", ta: "2.89vw" }}>
          <Typography variant="h3" fontWeight={"600"} textAlign="center">
            Explore Other Services....
          </Typography>
        </Box>

        <Box
          maxWidth={{ ta: "73.96vw" }}
          // maxWidth={{ xs: "367px", sm: "585px", md: "887px" }}
          margin="auto"
        >
          <Grid
            container
            rowSpacing={{ xs: "6.00vw" ,ta: "1.74vw" }}
            columnSpacing={{ xs: "0.00vw", ta: "4.51vw" }}
          >
            {services.map((item) => (
              <Grid
                item
                xs={12}
                ta={6}
                key={item.title}
                // pb={{ xs: "14px", sm: "22px", md: "31px" }}
                // pt={{ xs: "19px", sm: "30px", md: "42px" }}
              >
                <Box
                  minHeight={{ xs: "42.20vw", ta: "20.37vw" }}
                  py={{ xs: "9.60vw", ta: "3.82vw" }}
                  // pl={{ xs: "18px", sm: "29px", md: "42px" }}
                  px={{ xs: "10.00vw", ta: "2.89vw" }}
                  height="100%"
                  // mr={{ xs: "25px", sm: "40px", md: "57px" }}
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    bgcolor: "#111111",
                    color: "white",
                  }}
                >
                  <Stack height="100%" justifyContent={"space-between"}>
                    <Box width={"100%"} maxWidth={{ ta: "82.80vw" }}>
                      <Typography
                        variant="h5"
                        fontSize={{ xs: "0.83em", ta: "1.74vw" }}
                        fontWeight={"800"}
                      >
                        {" "}
                        {item.title}{" "}
                      </Typography>
                      <Typography
                        fontSize={{ xs: "0.58em", ta: "1.16vw" }}
                        mt={{ xs: "4.00vw", ta: "0.98vw" }}
                      >
                        {" "}
                        {item.text}{" "}
                      </Typography>
                    </Box>
                    <Box mt={"8px"}>
                      <Button
                        sx={{
                          color: "white",
                          fontSize: { xs: "1.00em", ta: "1.39vw" },
                          padding: "0",
                          textTransform: "capitalize",
                        }}
                        endIcon={<FaArrowCircleRight fontSize="inherit" />}
                      >
                        {" "}
                        <Typography fontSize={{ xs: "0.58em", ta: "0.93vw" }}>
                          Explore services
                        </Typography>
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Stack
        mt={{ xs: "13.60vw", ta: "5.79vw" }}
        direction={{ xs: "row", ta: "row" }}
        alignItems={"center"}
        // columnGap={{ xs: "0px", sm: "50px", md: "70px" }}
        // spacing={{ xs: "28px", sm: "44px", md: "63px" }}
      >
        <Box
          display={{ xs: "flex-item", ta: "none" }}
          height={{ xs: "49.40vw", ta: "0.00vw" }}
          width={{ xs: "6.00vw", ta: "0.00vw" }}
          bgcolor="#0D0D0D"
          mr={{ xs: "7.40vw", ta: "0.00vw" }}
        />
        <Box
          display={{ xs: "none", ta: "flex-item" }}
          ml={{ xs: "0.00vw", ta: "2.89vw" }}
          mr={{ xs: "0.00vw", ta: "5.27vw" }}
          width="100%"
          maxWidth={{ xs: "0.00vw", ta: "50.12vw" }}
          // height={{ xs: "185px", sm: "295px", md: "30.79vw" }}
        >
          <img src={cacImage} alt="cac_image" width="100%" />
        </Box>
        <Box
          maxWidth={{ xs: "83.60vw", ta: "37.15vw" }}
          width="100%"
          // pb={{ xs: "6px", sm: "10px", md: "15px" }}
          // px={{ xs: "3px", sm: "5px", md: "8px" }}
          // px={{ xs: "23px", sm: "37px", md: "0" }}
        >
          <Typography variant="h3" fontSize={{ xs: "1.25em", ta: "2.31vw" }}>
            CAC Verification
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "0.67em", ta: "1.39vw" }}
            // fontSize={{ md: "19px" }}
            mt={{ xs: "8px", md: "0.58vw" }}
          >
            Aid in the authentication and fact-checking of business records and
            registration within a few minutes.
          </Typography>
          <Box mt={{ xs: "6.00vw", ta: "1.74vw" }}>
            <CustomButton light text="Explore tools" />
          </Box>
        </Box>
      </Stack>

      <Box
        borderRadius={{
          xs: "24.60vw 0 0 0 ",
          ta: "20.20vw 0 0 0 ",
        }}
        bgcolor="#F6F6F6"
        mt={{ xs: "9.20vw", ta: "5.79vw" }}
        pt={{ xs: "18.40vw", ta: "5.79vw" }}
        pb={{ xs: "18.80vw", ta: "5.61vw" }}
        pl={{ xs: "6.00vw", ta: "8.91vw" }}
        pr={{ xs: "10.00vw", ta: "2.89vw" }}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Box
            width="100%"
            maxWidth={{ xs: "75.80vw", ta: "37.15vw" }}
            mr={{ xs: "0.00vw", ta: "4.05vw" }}
          >
            <Box>
              <Typography
                variant="h2"
                fontSize={{ xs: "1.25em", ta: "3.47vw" }}
                fontWeight={"600"}
              >
                Bank Services
              </Typography>
              <Typography
                mt={{ xs: "8px", md: "0.58vw" }}
                fontSize={{ xs: "0.58em", ta: "1.39vw" }}
              >
                We offer a good number of services to assist you in managing
                your company and your customers banking transactions.
              </Typography>
            </Box>
            <Stack
              mt={{ xs: "10.00vw", ta: "4.92vw" }}
              rowGap={{ xs: "6.00vw", ta: "4.40vw" }}
            >
              {bankServices.map((item) => (
                <Box key={item.title}>
                  <Typography
                    fontSize={{ xs: "0.83em", ta: "1.74vw" }}
                    // variant="h5"
                    fontWeight={"600"}
                  >
                    {" "}
                    {item.title}{" "}
                  </Typography>
                  <Typography
                    mt={{ xs: "8px", md: "0.58vw" }}
                    fontWeight={"300"}
                    fontSize={{ xs: "0.58em", ta: "1.04vw" }}
                  >
                    {" "}
                    {item.text}{" "}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
          <Box
            maxWidth={{ xs: "0.00vw", ta: "47.11vw" }}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img src={serviceImage} alt="servce _iimg" width="100%" />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
