import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AiFillPhone } from "react-icons/ai";
import { BsSquareFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";

const serviceList = [
  "Bank Services",
  "KYC Verification",
  "CAC Verification",
  "Payments and Wallet API’s",
  "OTP’s Notification Services",
  "Virtual Account Services",
  "Virtual/Physical Debit Card",
  "Account Statement Verification",
];

export default function GetStartedForm({ contact }) {
  return (
    <Box
      mt={{ xs: "108px", sm: " 124px", md: "12.96vw" }}
      pl={{ xs: "25px", sm: " 97px", md: "10.13vw" }}
      //   pr={"25px"}
      pr={{ xs: "25px", md: "5.84vw" }}
      pt={{ xs: "80px", md: "5.79vw" }}
      pb={{ xs: "111px", md: "7.18vw" }}
      // mt={{ xs: "16px", sm: "25px", md: "36px" }}
      borderRadius={{
        xs: "105px 0 0 0 ",
        sm: "193px 0 0 0 ",
        md: "20.20vw 0 0 0 ",
      }}
      bgcolor="#F6F6F6"
    >
      <Box maxWidth={{ sm: "46.93vw" }}>
        <Typography
          variant="h2"
          fontSize={{ xs: "24px", sm: " 33px", md: "3.47vw" }}
          fontWeight={"616"}
        >
          Get Started With Blacbox
        </Typography>
        <Typography
          //   fontWeight={{ md: "19px" }}
          fontSize={{ xs: "13px", sm: " 13px", md: "1.39vw" }}
          mt={{ xs: "3px", sm: "5px", md: "7px" }}
        >
          As a business, we provide our customers with value by leveraging
          stable, practical, and high–quality API technology.
        </Typography>
      </Box>
      <Box mt={{ xs: "39px", sm: " 54px", md: "5.67vw" }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} md={5}>
            <List dense>
              {serviceList.map((item) => (
                <ListItem
                  dense
                  sx={{
                    fontSize: { xs: "13px", sm: " 13px", md: "1.39vw" },
                    mb: { xs: "24px", md: "1.79vw" },
                  }}
                  key={item}
                >
                  <BsSquareFill fontSize="inherit" />
                  <ListItemText
                    sx={{ ml: { xs: "14px", md: "1.04vw" } }}
                    primaryTypographyProps={{
                      fontSize: { xs: "13px", sm: " 13px", md: "1.39vw" },
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {item}{" "}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <Box
                bgcolor={"#111111"}
                color={"white"}
                width="100%"
                ml="4px"
                borderRadius={{ xs: "8px", md: "0.58vw" }}
                maxWidth={{ md: "50.52vw" }}
                minHeight={{ xs: "519px", md: "38.66vw" }}
                px={{ xs: "32px", sm: "41px", md: "4.28vw" }}
                pt={{ xs: "26px", md: "1.91vw" }}
                pb={{ xs: "29px", md: "2.84vw" }}
              >
                <Typography
                  variant="h5"
                  fontSize={{ xs: "19px", md: "1.74vw" }}
                  fontWeight={"600"}
                >
                  Contact Form
                </Typography>
                <Stack
                  mt={{ xs: "28px", md: "2.08vw" }}
                  rowGap={{ xs: "39px", md: "2.89vw" }}
                >
                  <TextField
                    variant="standard"
                    placeholder="Business Name"
                    inputProps={{
                      sx: {
                        color: "white",
                        pb: { xs: "31px", md: "1.74vw" },
                        fontSize: { xs: "13px", sm: "13px", md: "1.39vw" },
                      },
                    }}
                    sx={{
                      borderBottom: {
                        xs: "1px dashed white",
                        md: "0.12vw dashed white",
                      },
                      maxWidth: { xs: "479px", md: "35.30vw" },
                    }}
                  /> 
                  <TextField
                    variant="standard"
                    placeholder="Phone"
                    type="tel"
                    inputProps={{
                      sx: {
                        color: "white",
                        pb: { xs: "31px", md: "1.74vw" },
                        fontSize: { xs: "13px", sm: "13px", md: "1.39vw" },
                      },
                    }}
                    sx={{
                      borderBottom: {
                        xs: "1px dashed white",
                        md: "0.12vw dashed white",
                      },
                      maxWidth: { xs: "479px", md: "35.30vw" },
                    }}
                  />
                  <TextField
                    variant="standard"
                    placeholder="Email"
                    type="email"
                    inputProps={{
                      sx: {
                        color: "white",
                        pb: { xs: "31px", md: "1.74vw" },
                        fontSize: { xs: "13px", sm: "13px", md: "1.39vw" },
                      },
                    }}
                    sx={{
                      borderBottom: {
                        xs: "1px dashed white",
                        md: "0.12vw dashed white",
                      },
                      maxWidth: { xs: "479px", md: "35.30vw" },
                    }}
                  />
                  <Box
                    width="100%"
                    fontSize={{ xs: "13px", sm: "13px", md: "1.39vw" }}
                  >
                    <Box mb={{ xs: "6px", md: "0.41vw" }}>
                      <label
                        style={{ fontSize: "inherit" }}
                        for="Company Name"
                        class="form__label"
                      >
                        Message
                      </label>
                    </Box>
                    <TextField
                      minRows={3}
                      multiline
                      variant="outlined"
                      inputProps={{
                        sx: {
                          color: "white",
                          pb: { xs: "31px", md: "1.74vw" },
                          fontSize: { xs: "13px", sm: "13px", md: "1.39vw" },
                        },
                      }}
                      sx={{
                        border: {
                          xs: "1px dashed white",
                          md: "0.12vw dashed white",
                        },
                        width: "100%",
                        //   maxWidth: { xs: "479px", md: "35.30vw" },
                      }}
                    />
                  </Box>
                  {/* <div class="form__group">
                    <input
                      type="text"
                      id="fullname"
                      class="form__field"
                      placeholder="Business Name"
                    />
                    <label for="name" class="form__label">
                      Business Name
                    </label>
                  </div>
                  <div class="form__group">
                    <input
                      type="email"
                      id="email"
                      class="form__field"
                      placeholder="Email"
                    />
                    <label for="Email" class="form__label">
                      Email
                    </label>
                  </div>
                  <div class="form__group">
                    <Box fontSize={{ xs: "13px", sm: "13px", md: "1.39vw" }}>
                      <label
                        style={{ fontSize: "inherit" }}
                        for="Company Name"
                        class="form__label"
                      >
                        Message
                      </label>
                    </Box>
                    <textarea
                      style={{ borderRadius: "5px" }}
                      type="text"
                      id="company"
                      class="message"
                      placeholder=""
                    />
                  </div>
                    */}

                  <Button
                    sx={{
                      backgroundColor: "#E2C703",
                      fontSize: { xs: "13px", md: "0.93vw" },
                      boxShadow: {
                        xs: "0 3px 2px 2px  rgba(255, 255, 255, 0.25)",
                        md: "0 0.23vw 0.17vw 0.17vw rgba(255, 255, 255, 0.25)",
                      },
                      fontWeight: "600",
                      width: "100%",
                      borderRadius: { xs: "8px", md: "0.58vw" },
                      color: "black",
                      maxWidth: { xs: "161px", md: "11.86vw" },
                      // px:"32px",
                      height: { xs: "47px", md: "3.47vw" },
                      "&:hover": {
                        backgroundColor: "#E2C703",
                        color: "black",
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Stack>
              </Box>
            </Box>
            {contact ? (
              <Box
                borderRadius={{ xs: "8px", md: "0.58vw" }}
                width="100%"
                maxWidth={{ md: "50.52vw" }}
                mt={{ xs: "39px", md: "2.89vw" }}
                px={{ xs: "24px", md: "1.91vw" }}
                pt={{ xs: "26px", md: "1.91vw" }}
                pb={{ xs: "29px", md: "2.84vw" }}
                bgcolor="#111111"
                color="white"
              >
                <Typography
                  fontWeight={"500"}
                  fontSize={{ xs: "19px", md: "1.74vw" }}
                >
                  Would you like to speak with us as soon as possible?
                </Typography>
                <Stack
                  mt={{ xs: "24px", sm: "24px", md: "2.49vw" }}
                  direction="row"
                  alignItems={"center"}
                  fontSize={{ xs: "14px", md: "1.04vw" }}
                  columnGap={{ xs: "9px", md: "0.64vw" }}
                >
                  <IoIosMail style={{ fontSize: "inherit" }} />
                  <Typography> support@blacbox.app </Typography>
                </Stack>
                <Stack
                  direction="row"
                  columnGap={{ xs: "9px", md: "0.64vw" }}
                  alignItems={"center"}
                  fontSize={{ xs: "14px", md: "1.04vw" }}
                  mt={{ xs: "24px", sm: "24px", md: "2.49vw" }}
                >
                  <AiFillPhone />
                  <Typography>01 630 4704 </Typography>
                </Stack>
                <Stack
                  direction="row"
                  columnGap={{ xs: "9px", md: "0.64vw" }}
                  alignItems={"center"}
                  fontSize={{ xs: "14px", md: "1.04vw" }}
                  mt={{ xs: "24px", sm: "24px", md: "2.49vw" }}
                >
                  <AiFillPhone />
                  <Typography>0818 332 2155 </Typography>
                </Stack>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
