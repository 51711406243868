import {
  Box,
  Typography,
  Button,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import jobnavbar from "../../images/jobappbar/jobnavbar.png";
import NavBar from "./nav";
import formobileimage from "../../images/jobappbar/formobileimage.png";
// import formobileimage from "."

// import jobnavbar from "../images/jobappbar/jobnavbar.png";
// import NavBar from "../component/layout/nav";

export default function JobAppBar() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: {
            xs: `url(${formobileimage})`,
            sm: `url(${jobnavbar})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // minHeight: { sm: "389px", md: "460px" },
          pb: { xs: "108px", md: "9.49vw" },
        }}
      >
        <Box>
          <NavBar light />
        </Box>

        <Box mx="auto">
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                // fontSize: { xs: "14px", sm: "22px", md: "25px" },
                // lineHeight: { xs: "17px", sm: "27px", md: "38px" },
                color: "#FFFFFF",
                mt: { xs: "63px", sm: "100px", md: "10.42vw" },
                textAlign: "center",
              }}
            >
              Apply for the position of a Product Designer
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 300,
                // fontSize: { sm: "10px", md: "14px" },
                // lineHeight: {xs:"46px", sm:"73px", md:"104px"},
                textAlign: "center",
                color: "#FFFFFF",
                mx: "auto",
                mt: { xs: "7px", sm: "11px", md: "1.16vw" },
                maxWidth: { xs: "222px", sm: "354px", md: "36.98vw" },
              }}
            >
              Join our team as we will help you in realizing your full potential
              as you work with us to create a fully accessible payments
              infrastructure.
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Link to="/jobs/form" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  border: "1px solid #E2C703",
                  borderRadius: "5px",
                  width: { xs: "147px", sm: "234px", md: "24.42vw" },
                  height: { xs: "38px", sm: "60px", md: "6.25vw" },
                  mt: { xs: "24px", sm: "38px", md: "3.94vw" },
                  fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
                  // fontFamily: "Montserrat",
                  fontWeight: 500,

                  // fontSize: { sm: "16px", md: "16px" },
                  textAlign: "center",
                  letterSpacing: "0.02em",
                  color: "#FFFFFF",
                }}
              >
                Apply Now
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
