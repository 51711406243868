import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavBar from "../component/layout/nav";
import "../style/css/waitlist/waitlistform.css";

import heroImage from "../images/contact/contact_hero_2.svg";
import GetStartedForm from "../component/ui/getStartedForm";

const cardContent = [
  {
    title: "Product Support",
    text: "If you have any questions or need aid with services, please contact us via email or phone.",
  },
  {
    title: "Sales Support.",
    text: "Contact our sales team for more information on the worthiness and accessibility of our products.",
  },
];

export default function ContactPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Box
        overflow={"hidden"}
        sx={{ background: { xs: `url(${heroImage})`, md: "white" } }}
        color={{ xs: "white", md: "black" }}
        pb={{ xs: "39px", md: "0" }}
      >
        <Box px={{ xs: "24px", sm: "27px", md: "4.69vw" }}>
          <NavBar light={isMobile} />
        </Box>
        <Stack
          flexDirection={"row"}
          display={"flex"}
          pt={{ xs: "63px", sm: "100px", md: "10.47vw" }}
          // minHeight={{ xs: "241px", sm: "385px", md: "545px" }}
          // mb={{ xs: "14px", sm: "23px", md: "32px" }}
          pl={{ xs: "6.00vw", ta: "4.69vw" }}
          pr={{ xs: "6.00vw", ta: "0.00vw" }}
        >
          <Box width="100%">
            <Typography variant="h2" fontWeight={"616"}>
              Contact Us
            </Typography>
            <Box mt={{ xs: "7.20vw", sm: "2.89vw" }}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                columnGap={{ xs: "0.00vw", sm: "2.89vw" }}
                rowGap={{ xs: "8.20vw", sm: "0.00vw" }}
              >
                {cardContent.map((item) => (
                  <Box
                    key={item.title}
                    borderRadius={{xs:"2.00vw", ta:"0.58vw"}}
                    sx={{
                      filter: "drop-shadow(-5px 7px 6px rgba(0, 0, 0, 0.15))",
                    }}
                    bgcolor={{ xs: "white", md: "#111111" }}
                    color={{ xs: "#111111", md: "white" }}
                    width="100%"
                    maxWidth={{ xs: "78.80vw", sm: "38vw", md: "25.52vw" }}
                    minHeight={{ xs: "226px", md: "18.92vw" }}
                    px={{ xs: "39px", md: "2.89vw" }}
                    pb={{ xs: "12px", md: "0.87vw" }}
                    // mr={{ xs: "18px", sm: "28px", md: "40px" }}
                    // padding={"39px"}
                  >
                    <Stack height="100%" justifyContent={"space-between"}>
                      <Box
                        mt={{ xs: "30px", sm: "33px", md: "3.41vw" }}
                        sx={{}}
                        width="80%"
                        maxWidth={{ xs: "50.60vw", md: "14.64vw" }}
                      >
                        <Typography
                          fontSize={{ xs: "0.92em", md: "1.39vw" }}
                          fontWeight="500"
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          letterSpacing="0"
                          mt={{ xs: "8px", sm: "11px", md: "1.16vw" }}
                          fontSize={{ xs: "0.67em", md: "0.93vw" }}
                        >
                          {item.text}
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          mt: { xs: "10px", sm: "16px", md: "23px" },
                          backgroundColor: "#E2C703",
                          fontSize: { xs: "13px", md: "0.93vw" },
                          boxShadow: {
                            xs: "0 3px 2px 2px  rgba(255, 255, 255, 0.25)",
                            md: "0 0.23vw 0.17vw 0.17vw rgba(255, 255, 255, 0.25)",
                          },
                          fontWeight: "600",
                          width: "100%",
                          borderRadius: { xs: "8px", md: "0.58vw" },
                          color: "black",
                          maxWidth: { xs: "161px", md: "11.86vw" },
                          // px:"32px",
                          height: { xs: "47px", md: "3.47vw" },
                          "&:hover": {
                            backgroundColor: "#E2C703",
                            color: "black",
                          },
                        }}
                      >
                        Go to help center
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>

          <Box
            mb={{ md: "-3.82vw" }}
            // minHeight={{ xs: "184px", sm: "294px", md: "417px" }}
            display={{ xs: "none", md: "block" }}
            // zIndex={-1}
            width="100%"
            maxWidth={{ xs: "714px", md: "52.55vw" }}
            ml={{ md: "-7.81vw" }}
          >
            <img src={heroImage} alt="hero_img" width="100%" height="100%" />
          </Box>
        </Stack>
      </Box>

      <Box>
        <GetStartedForm contact />
      </Box>
    </Box>
  );
}
