import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { useState } from "react";

import slideImage05 from "../../images/partners/slide05.svg";
import slideImage04 from "../../images/partners/slide04.svg";
import slideImage02 from "../../images/partners/slide03.svg";
import slideImage03 from "../../images/partners/slide02.svg";
import slideImage01 from "../../images/partners/slide01.svg";
import VerticalBar from "./verticalBar";

const slideList = [
  { title: "Branding partners", image: slideImage01 },
  { title: "Comfortable spaces", image: slideImage02 },
  { title: "Technology partners", image: slideImage03 },
  { title: "EduTech affiliates", image: slideImage04 },
  { title: "Fintech alliance", image: slideImage05 },
];

export default function ListSlide({ text }) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Stack direction="row">
      <Box>
        <VerticalBar value={activeIndex} total={slideList.length} />
      </Box>

      <Box
        mt={{ xs: "31px", md: "1.50vw" }}
        // bgcolor="#EEEEEE"
        border={"1px solid rgba(0,0,0,0.1)"}
        height={"fit-content"}
        width={{ xs: "100%" }}
        maxWidth={{ md: "25.69vw" }}
        mr={{ xs: "0", md: "3.30vw" }}
        // px={{ xs: "11px", sm: "17px", md: "25px" }}
      >
        <Box
          sx={{}}
          // mt={{ xs: "6px", sm: "9px", md: "13px" }}
          // maxWidth={{ sm: "160px", md: "226px" }}
        >
          <List disablePadding>
            {slideList.map((item, index) => {
              const isActive = index === activeIndex;
              return (
                <>
                  <ListItem
                    onMouseOver={() => setActiveIndex(index)}
                    key={item.title}
                    sx={{
                      bgcolor: isActive ? "#E2C703" : "transparent",
                      // height: "56px",
                      p: { xs: "19px", md: "1.39vw" },
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: isActive ? "600" : "400",
                        fontFamily: "Montserrat",
                        fontSize: isActive
                          ? { xs: "14px", md: "1.16vw" }
                          : { xs: "13px", md: "1.04vw" },
                        color: isActive ? "white" : "#000000",
                      }}
                      primary={item.title}
                      mb={3}
                    />
                  </ListItem>
                  <Divider />

                  <Box
                    width="100%"
                    sx={{
                      display: {
                        md: "none",
                        // sm: "none",
                        xs: index === activeIndex ? "inline" : "none",
                      },
                    }}
                  >
                    <img src={item.image} alt="" width={"100%"} />
                  </Box>
                </>
              );
            })}
          </List>
        </Box>
      </Box>

      <Box
        width={{ xs: "100%" }}
        // ml="-50px"
        // maxHeight={{ xs: "155px", sm: "247px", md: "350px" }}
        // maxWidth={{ xs: "324px", sm: "517px", md: "732px" }}
        maxWidth={{ md: "54.86vw" }}
        display={{ xs: "none", md: "block" }}
      >
        <img
          src={slideList[activeIndex].image}
          alt=""
          height=""
          width={"100%"}
        />
      </Box>
    </Stack>
  );
}
