import React from "react";
import { Box, Typography } from "@mui/material";

//images
import dashSmall from "../../images/pricing/dash-small.png";
import dashBig from "../../images/pricing/dash-big.png";

export default function DashBoard() {
  return (
    <Box
      // width={{ xs: "100%", md: "95%" }}
      margin={"0 auto"}
      marginTop={{ xs: "79px", md: "5.79vw" }}
      marginBottom={{ xs: "39px", sm: " 50px", md: "5.21vw" }}
      px={{ xs: "24px", sm: " 55px", md: "5.79vw" }}
      // px={{ xs: "24px", sm: " 55px", md: "5.79vw" }}
    >
      <Typography
        variant="h4"
        color={"#000000"}
        fontWeight={"500"}
        fontSize={{ xs: "24px", md: "2.31vw" }}
        maxWidth={{ md: "43.46vw" }}
        //  lineHeight={"30px"}
        //  letterSpacing={"0.01em"}
        mb={2}
      >
        A Robust Dashboard That Can Keep You in Control of Your Business
      </Typography>
      <Box sx={{ display: { md: "flex" }, gap: "50px" }}>
        <Box>
          <Typography
            variant="body1"
            color={"#000000"}
            fontWeight={"500"}
            fontSize={{ xs: "14px", md: "1.04vw" }}
            // lineHeight={"25px"}
            // letterSpacing={"0.01em"}
            mb={{ xs: "31px", sm: " 33px", md: "3.47vw" }}
            // padding={"30px"}
          >
            Get free access to deep insights with our payment analytics module
            to make data-driven decisions
          </Typography>
          <Box
            width="100%"
            mx={{ xs: "auto", md: 0 }}
            px={{ xs: "35px", sm: "79px",md:0 }}
            maxWidth={{ md: "34.61vw" }}
            minHeight={{ xs: "182px", sm: " 215px", md: "22.45vw" }}
            height={{  md: "22.45vw" }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={dashSmall}
              alt="dash small"
            />
          </Box>
        </Box>
        <Box
          mt={{ md: "0px", xs: "1.68vw" }}
          width={{ xs: "100%" }}
          maxWidth={{ md: "47.11vw" }}
          minHeight={{ xs: "205px", sm: " 277px", md: "28.94vw" }}
          height={{  md: "28.94vw" }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={dashBig}
            alt="dash big"
          />
        </Box>
      </Box>
    </Box>
  );
}
