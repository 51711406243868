import {
  Box,
  Typography,
  Button,
  TextField,
  InputLabel,
  FormControlLabel,
  IconButton,
} from "@mui/material"
import JobAppBar from "../component/layout/jobappbar"
import Radio from "@mui/material/Radio"
import { useState } from "react"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import jobpagebiii from "../images/jobappbar/jobpagebiii.png"
// import  formobileimage from "../images/jobappbar/formobileimage.png"
import { useNavigate } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa"
// import formControl from "@mui/material";
// import {}

export default function JobThirdPage() {
  const [value, setValue] = useState("yes")
  const navigate = useNavigate()

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <Box>
      <Box >
        <JobAppBar />
      </Box>

      <Box mt={{xs:"8px", sm:"13px", md:"1.33vw"}} px={{xs:"35px", sm:"55px", md:"5.79vw"}}>
          <IconButton 
          sx={{ fontSize:{xs:"19px",md:"1.74vw"}
        }}
          onClick={() => navigate(-1)}>
            <FaArrowLeft fontSize="inherit" />
          </IconButton>
        </Box>
      <Box
        px={{xs:"35px", sm:"55px", md:"5.79vw"}}
        mt={{ xs: "34px", sm: "54px", md: "77px" }}
        sx={{
          backgroundImage: `url(${jobpagebiii})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          mt: "-30px",
        }}
      >
        <Typography
          // varaint="h4"
          textAlign="center"
          sx={{
            mt: {xs:"39px",sm:" 55px",md:"5.79vw"},
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: {xs:"19px",sm:" 22px",md:"2.31vw"},
            // lineHeight: "49px",
            color: "#111111",
          }}
        >
          Application Form
        </Typography>
        <Box sx={{ maxWidth: {xs:"336px", sm:"536px", md:"55.96vw"}, }}>
          <Typography
          // variant="subtitle1"
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: {xs:"16px",md:"1.39vw"},
              color: "#111111",
              mt: {xs:"12px", sm:"19px", md:"2.03vw"},
            }}
          >
            Personal Information
          </Typography>
          <InputLabel
            // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // lineHeight: "37px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            Full Name
          </InputLabel>
          <TextField
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"}, }}
            size="small"
            variant="outlined"
            fullWidth
          />

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "37px",
              color: " #303E49",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            Email Address
          </InputLabel>
          <TextField
            size="small"
            variant="outlined"
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"} }}
            fullWidth
          />

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "37px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            Phone Number
          </InputLabel>
          <TextField
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"} }}
            size="small"
            variant="outlined"
            fullWidth
          />

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "37px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            State
          </InputLabel>

          <TextField
            size="small"
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"} }}
            variant="outlined"
            fullWidth
          />

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "37px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            Address
          </InputLabel>

          <TextField
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"} }}
            size="small"
            variant="outlined"
            fullWidth
          />

          <Typography
          // variant="subtitle1"
            sx={{
              mt: { xs: "14px", sm: "22px", md: "31px" },
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize:{xs:"16px",md:"1.39vw"},

              // fontSize: { xs: "8px", sm: "13px", md: "19px" },
              // lineHeight: "24px",
              color: " #303E49",
            }}
          >
            Professional Background
          </Typography>
          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "24px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"17px", sm:"28px", md:"2.89vw"},
            }}
          >
            Most Recent Degree
          </InputLabel>

          <TextField
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"} }}
            size="small"
            variant="outlined"
            fullWidth
          />

          <FormControl>
            <FormLabel
            // variant="h5"
              sx={{
                mt:{xs:"14px", sm:"22px", md:"2.31vw"},
                fontFamily: "Montserrat",
                fontWeight: "400",
                // fontSize: { xs: "10px", sm: "16px", md: "23px" },
                // lineHeight: "24px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
                color: " #303E49",
              }}
            >
              Are you currently employed?
            </FormLabel >
            <RadioGroup row value={value} onChange={handleChange} sx={{mt:{xs:"8px", sm:"13px", md:"1.33vw"}, }} >
              <FormControlLabel value="yes"  control={<Radio 
              sx={{width:{xs:"10px", sm:"17px", md:"1.74vw"},   }}
              />} label="Yes"  />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "24px",
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"18px", sm:"29px", md:"3.07vw"},
            }}
          >
            Resume Upload
          </InputLabel>
          <Button
                    fullWidth
                      component="label"
                      sx={{
                        maxWidth: {xs:"139px", sm:"221px", md:"23.09vw"},
                        
                        padding: "10px",
                        background: "transparent",
                        border: "1px solid #E7E7E7",
                        // marginLeft: "-60px",
                        mt: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: "left",
                          marginRight: "auto",
                          textTransform: "none",
                          color: "#E7E7E7",
                        }}
                      >
                        Choose File
                      </Typography>
                      <input hidden accept="file/*" multiple type="file" />
                    </Button>

          {/* <TextField
            size="small"
            type="file"
            label="Choose File"
            sx={{ mt: { xs: "7px", sm: "11px", md: "10px" } }}
            variant="outlined"
            // fullWidth
          /> */}

          <InputLabel
          // variant="h5"
            fullWidth
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "400",
              // fontSize: { xs: "10px", sm: "16px", md: "23px" },
              // lineHeight: "24px",
              
              fontSize:{xs:"16px",sm:" 17px",md:"1.74vw"},
              color: " #303E49",
              marginTop: {xs:"14px", sm:"22px", md:"2.31vw"},
            }}
          >
            Portfolio Link
          </InputLabel>

          <TextField
            size="small"
            sx={{ mt: {xs:"7px", sm:"11px", md:"1.16vw"}, }}
            variant="outlined"
            fullWidth
          />

          <Box
            sx={{
              marginTop: {xs:"17px", sm:"27px", md:"2.84vw"},
              mb: {xs:"41px", sm:"65px", md:"6.77vw"},
            }}
          >
            <Button
              sx={{
                background: "#111111",
                borderRadius: "5px",
                width:"100%",
                maxWidth: {xs:"336px", sm:"536px", md:"55.96vw"},
                height: {xs:"41px", sm:"65px", md:"6.77vw"},
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: {xs:"13px",sm:" 17px",md:"1.74vw"},
                textAlign: "center",
                color: "#FFFFFF",
                "&:hover": { backgroundColor: "#111111", color: "white" },
              }}
            >
              Submit Application
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
