import { Box, Button, Grid, Stack, Typography, Card } from "@mui/material"
import "../style/css/waitlist/waitlist.css"
import icon from "../images/signin/icon.png"
import { Link, NavLink } from "react-router-dom"
export default function Waitlist() {
  return (
    <Box>
      <Grid container px={1}>
        <Grid item md={12}  xs={12}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            px={{xs:"11px", sm:"18px", md:"1.91vw"}}
            mt={{xs:"13px", sm:"20px", md:"2.08vw"}}
            sx={{ paddingRight: { md: "50px", xs: "0px" },
          // border:"solid red",
        }}
        >
            <Box sx={{
              maxWidth:{xs:"51px", sm:"82px", md:"8.56vw"},
              width:"100%",
                height:{xs:"28px", sm:"45px", md:"4.69vw"},
            }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={icon} alt="icon" width={"100%"} />
            </Link>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container className="bg">
            <Card
              sx={{
                margin: { md: "auto", xs: "100px auto" },
                background: "transparent",
                boxShadow: "none",
              }}
            >
              <Stack alignItems="center" spacing={1}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography
                    variant="h3"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    2000{" "}
                  </Typography>
                  <Typography variant="h3" sx={{ disply: "inline" }}>
                    People ahead of you
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography
                    variant="h3"
                    component="span"
                    sx={{ fontWeight: "bold" }}
                  >
                    28{" "}
                  </Typography>
                  <Typography variant="h3"> People behind you </Typography>
                </Stack>
                <NavLink
                  to={"/waitlist/form"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#E2C703",
                      color: "black",
                      textTransform: "none",
                      fontWeight: "600",
                      width: {xs:"147px", sm:"234px", md:"24.42vw"},
                      height:{xs:"38px", sm:"60px", md:"6.25vw"},
                      fontSize:{xs:"10px", sm:"17px", md:"1.74vw"},
                      mt:{xs:"10px", sm:"17px", md:"1.74vw"},
                      padding: "20px",
                      "&:hover": {
                        backgroundColor: "#E2C703",
                        color: "black",
                        textTransform: "none",
                      },
                    }}
                  >
                    Join Waitlist
                  </Button>
                </NavLink>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
