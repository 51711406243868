import { Stack } from "@mui/material";
import google from "../../images/customer/googles.png";
import momo from "../../images/customer/momo.png";
import single from "../../images/customer/single.svg";
import devrecruit from "../../images/customer/devrecruit.png";
// import conf from "../../images/customer/conf.png";
import wallx from "../../images/customer/wallx.png";

// const imageList = [
//   { image: google },
//   { image: momo },
//   { image: conf },
//   { image: wallx },
//   { image: devrecruit },
// ];

export default function ImageStack() {
  return (
    <Stack direction="row" width="100%" columnGap={{ xs: "0.00vw", ta: "2.31vw" }} bgcolor="black">
      <Stack
        sx={{ width: { xs: "0.00vw", ta: "31.37vw" } }}
        rowGap={{ xs: "0.00vw", ta: "2.31vw" }}
        // mr={{ xs: "0.00vw", ta: "2.31vw" }}
      >
        <img src={google} alt="" width="100%" />

        <img src={momo} alt="" width="100%" />
      </Stack>
      <Stack
        sx={{ width: { xs: "0.00vw", ta: "28.88vw" } }}
        rowGap={{ xs: "0.00vw", ta: "2.31vw" }}
        // mr={{ xs: "0.00vw", ta: "3.24vw" }}
      >
        <img src={single} alt="" width="100%" />
      </Stack>
      <Stack
        sx={{ width: { xs: "0.00vw", ta: "34.20vw" } }}
        rowGap={{ xs: "0.00vw", ta: "2.31vw" }}
      >
        <img src={wallx} alt="" width="100%" />

        <img src={devrecruit} alt="" width="100%" />
      </Stack>
      {/* <Slide arrows={false} slidesToShow={3}>
        {imageList.map((item, index) => (
          <Box
            key={index}
            width={{ xs: "97.80vw", ta: "0.00vw" }}
            height="94.40vw"
          >
            <img src={item.image} alt="image" width="100%" />
          </Box>
        ))}
      </Slide> */}
    </Stack>
  );
}
