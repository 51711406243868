import React from "react";
import { Box, Grid, Typography, Stack, useMediaQuery } from "@mui/material";
import NavBar from "../component/layout/nav";
import AccordionBlock from "../component/ui/accordionBlock";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// images
import iconImg from "../images/customer/icon.png";
import bgImage from "../images/customer/bg.svg";
import business from "../images/customer/business-benefits.png";
// import logo from "../images/customer/ideazone.svg";
// import bg1 from "../images/customer/googles.png";
import solutionAgencyImage from "../images/customer/bank_payment.svg";
import solutionRecurringImage from "../images/customer/solution_recuring.svg";
import solutionTransferImage from "../images/customer/solution_transfer.svg";
import solutionVerificationImage from "../images/customer/solution_verification.png";
import HelpCenter from "../component/ui/customer/helpCenter";
import { useTheme } from "@emotion/react";
import ImageStack from "../component/ui/imageStack";

// Icons
// import { BsArrowRight } from "react-icons/bs";

// const verify = [
//   {
//     number: "01",
//     title: "Image authentication",
//     content: "Complete facial recognition using the image upload ",
//   },
//   {
//     number: "02",
//     title: "Biometric verification",
//     content: "Complete facial recognition using the image upload ",
//   },
//   {
//     number: "03",
//     title: "Validate government IDs with OTPs",
//     content: "Complete facial recognition using the image upload ",
//   },
// ]

const accordionFaq = [
  {
    id: "01",
    title: "What is BlacBox? ",
    text: "BlacBox is a multi-API system that powers all services any business needs to help your business grow faster. ",
  },
  {
    id: "02",
    title: "What are the services BlacBox has? ",
    text: "KYC verification, payments, Bank Transfer, OTP services, Bill Payment, Virtual account number, Debit cards, etc ",
  },
  {
    id: "03",
    title: "How does a user or company sign up for BlacBox?",
    text: "Signing  up for BlacBox is very simple, simply click the signup button to access the form page where you will enter all your information to begin using our services. ",
  },
];

const bankSolutions = [
  { title: "Banking for Agencies", image: solutionAgencyImage },
  { title: "Recurring Payments", image: solutionRecurringImage },
  {
    title: "Verification of Bank Statements",
    image: solutionVerificationImage,
  },
  { title: "Bank Transfers", image: solutionTransferImage },
];

const businessBenefits = [
  "Reliable Integration",
  "Competitive pricing",
  "Developers tools",
  "Transaction API",
  "Customer Support",
];

export default function Customerpage() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Box
        overflow={"hidden"}
        sx={{
          background: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box px={{ xs: "24px", md: "5.79vw" }}>
          <NavBar light />
        </Box>
        <Box
          mt={{ xs: "86px", sm: "137px", md: "14.29vw" }}
          mb={{ xs: "104px", sm: "166px", md: "17.36vw" }}
          sx={{
            // margin: "100px auto",
            color: "#E2C703",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            width="100%"
            mx="auto"
            sx={{
              fontWeight: "500",
              maxWidth: { xs: "299px", sm: "429px", md: "44.73vw" },
            }}
          >
            The API Powerhouse Behind Millions of Businesses
          </Typography>
        </Box>
      </Box>

      <Box
        mt={{ xs: "39px", sm: "46px", md: "4.80vw" }}
        sx={{ textAlign: { xs: "left", md: "center" } }}
        px={{ xs: "23px", md: 0 }}
        maxWidth={{ xs: "76.40vw", md: "41.20vw" }}
        // maxWidth={{ sm: "388px", md: "550px" }}
        mx={{ md: "auto" }}
      >
        <Typography
          sx={{ fontWeight: "400" }}
          // fontSize={{ xs: "1.25em", md: "2.89vw" }}
          variant="h3"
        >
          We partner with Businesses to Verify Identity
        </Typography>
        <Typography
          variant="body2"
          mt={{ xs: "12px", md: "1.16vw" }}
          sx={{ color: "#4B4B4B" }}
          maxWidth={{ md: "34.43vw" }}
          mx="auto"
          // maxWidth={{ sm: "324px", md: "460px" }}
        >
          Our identification procedure ensures that getting to know your
          customer is simpler.Here are some benefits of using our trustworthy
          identification services.
        </Typography>
      </Box>

      <Box
        px={{ xs: "23px", md: "5.79vw" }}
        // pr={{ xs: "23px", md: "2.26vw" }}
        mt={{ xs: "15px", sm: "25px", md: "2.60vw" }}
      >
        <AccordionBlock
          imageProps={{
            maxWidth: { md: "40.74vw" },
            display: { xs: "none", md: "block" },
          }}
          columnGap={{ xs: "15px", sm: "23px", md: "2.43vw" }}
          data={accordionFaq}
        />
      </Box>

      <Box mt={{ xs: "55px", sm: "92px", md: "8.68vw" }}>
        {/* <Box
          // ml={{ xs: "23px", md: "5.79vw" }}
          mx={{ xs: "23px", md: "5.79vw" }}
          maxWidth={{ xs: "88.20vw", ta: "43.69vw" }}
          // maxWidth={{ sm: "411px", md: "583px" }}
        >
          <Typography variant="h3">
            Offering Banking Solutions to Fuel Business Transactions{" "}
          </Typography>
        </Box> */}
        <Stack
          // pl={{ xs: "23px", md: "5.79vw" }}
          px={{ xs: "23px", md: "5.79vw" }}
          // direction="row"
          // mt={{ xs: "33px", md: "2.89vw" }}
          // columnGap={{ xs: "17px", sm: "27px", md: "39px" }}
          className="slide-container"
          // flexWrap="wrap"
        >
          <Slide arrows={false} slidesToShow={isMobile ? 1 : isTablet ? 2 : 3}>
            {bankSolutions.map((item, index) => (
              <Box
                className="each-slide"
                key={index}
                mr={{ xs: "23px", md: "2.89vw" }}
                sx={{
                  display: "flex",
                }}
              >
                <Stack
                  key={item.title}
                  width="100%"
                  maxWidth={{ sm: "45.58", md: "25.58vw" }}
                  justifyContent="flex-end"
                  height={{ xs: "340px", md: "25.00vw" }}
                  sx={{
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: "cover",
                  }}
                >
                  <Box
                    minHeight={{ xs: "66px", md: "4.86vw" }}
                    borderRadius={{ xs: "8px", md: "0.58vw" }}
                    p={{ xs: "23px", md: "1.74vw" }}
                    bgcolor="black"
                    color="white"
                  >
                    <Typography variant="body1" fontWeight="600">
                      {item.title}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Slide>
        </Stack>
      </Box>

      <Box
        display={{ xs: "none", sm: "flex" }}
        pt={{ xs: "42px", sm: "68px", md: "7.06vw" }}
        pl={{ xs: "35px", sm: "57px", md: "5.90vw" }}
        pr={{ xs: "34px", sm: "54px", md: "5.67vw" }}
        pb={{ xs: "29px", sm: "46px", md: "4.80vw" }}
        mt={{ xs: "51px", sm: "82px", md: "8.68vw" }}
        sx={{
          background: `url(${business})`,
          width: "100%",
          borderTopLeftRadius: { xs: "93px", sm: "149px", md: "15.51vw" },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          // sx={{ padding: { xs: "25px", sm: "39px", md: "4.11vw" } }}
          justifyContent="space-between"
          columnGap={{ xs: "38px", sm: "60px", md: "6.37vw" }}
          rowGap={{ xs: "38px", sm: "60px", md: "85px" }}
          // alignItems="center"
        >
          <Grid item md={5}>
            <Box color="white" maxWidth={{ sm: "490px", md: "37.62vw" }}>
              <Typography variant="h3" fontWeight="500">
                For the benefit of your business, we are focused on Payments and
                Wallet APIs
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              rowSpacing={{ xs: "14px", sm: "22px", md: "2.31vw" }}
              mt={{ xs: "43px", md: "2.14vw" }}
              columnSpacing={{ xs: "0", sm: "20px", md: "2.08vw" }}
              justifyContent={{ xs: "flex-start", sm: "center" }}
            >
              {businessBenefits.map((item, index) => (
                <Grid item key={item.title} xs={11} sm={index ? 6 : 7}>
                  <Stack
                    direction="row"
                    width="100%"
                    minHeight={{ xs: "45px", sm: "49px", md: "5.09vw" }}
                    sx={{
                      border: "solid white",
                      background: "white",
                      borderRadius: { xs: "12px", md: "1.39vw" },

                      // width: "250px",
                    }}
                    alignItems="center"
                  >
                    <Box
                      width="100%"
                      maxWidth={{ xs: "28px", sm: "31px", md: "3.24vw" }}
                    >
                      <img src={iconImg} alt="" width="100%" />
                    </Box>
                    <Typography variant="body2"> {item} </Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={{ xs: "39px", md: "8.68vw" }}
        px={{ xs: "34px", sm: "54px", md: "5.79vw" }}
      >
        <Box
        // maxWidth={{ xs: "302px", sm: "100%" }}
        >
          <Typography variant="h3" sx={{ fontWeight: "400" }}>
            We accelerate growth for all types of businesses
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              maxWidth: { xs: "73.20vw", md: "35.30vw" },
              fontSize: { xs: "0.67em", md: "1.39vw" },
              mt: { xs: "7px", sm: "11px", md: "1.16vw" },
            }}
          >
            We offer business API solutions that will boost business success
            rates via our integration and models.
          </Typography>
        </Box>
        <Stack
          display={{ xs: "none", ta: "flex" }}
          mt="1.68vw"
          direction="row"
          flexWrap="wrap"
          spacing={"1.74vw"}
        >
          {/* <Box width={{ xs: "26.20vw", ta: "7.58vw" }}> */}
          <Box width={{ xs: "26.20vw", ta: "13.58vw" }}>
            <Typography
              fontSize={{ xs: "1.25em", ta: "1.74vw" }}
              sx={{ fontWeight: "600" }}
              variant="h5"
            >
              25 Start-Ups
            </Typography>
            <Typography
              letterSpacing={"0"}
              mt={{ xs: "0.00vw", ta: "0.58vw" }}
              fontSize={{ xs: "0.67em", ta: "0.93vw" }}
              sx={{ fontWeight: "400" }}
            >
              We have accelerated founders of various startups
            </Typography>
          </Box>
          {/* <Box width={{ xs: "45.40vw", ta: "13.14vw" }}> */}
          <Box width={{ xs: "45.40vw", ta: "21.14vw" }}>
            <Typography
              sx={{ fontWeight: "600" }}
              fontSize={{ xs: "1.25em", ta: "1.74vw" }}
              variant="h5"
            >
              10-years Combined Experience
            </Typography>
            <Typography
              letterSpacing={"0"}
              mt={{ xs: "0.00vw", ta: "0.58vw" }}
              fontSize={{ xs: "0.67em", ta: "0.93vw" }}
              sx={{ fontWeight: "400" }}
            >
              {/* Expedient launch of APIs on several payment platforms */}
              We have an experienced team
            </Typography>
          </Box>
          {/* <Box width={{ xs: "50.80vw", ta: "14.70vw" }}> */}
          <Box width={{ xs: "50.80vw", ta: "21.70vw" }}>
            <Typography
              sx={{ fontWeight: "600" }}
              fontSize={{ xs: "1.25em", ta: "1.74vw" }}
              variant="h5"
            >
              3 Locations
            </Typography>
            <Typography
              letterSpacing={"0"}
              mt={{ xs: "0.00vw", ta: "0.58vw" }}
              fontSize={{ xs: "0.67em", ta: "0.93vw" }}
              sx={{ fontWeight: "400" }}
            >
              {/* We witnessed BlacBox growth and expansion in one year */}
              We are constantly expanding our footprints
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        mt={{ xs: "31px", sm: "49px", md: "70px" }}
        sx={{
          display: { xs: "none", ta: "flex" },

          justifyContent: "center",
        }}
      >
        <ImageStack />
      </Box>

      <Box
        mb={{ xs: "34px", sm: "54px", md: "5.79vw" }}
        mt={{ xs: "51px", sm: "82px", md: "9.26vw" }}
      >
        <Box mt={{ xs: "16px", sm: "26px", md: "36px" }}>
          <HelpCenter />
        </Box>
      </Box>
    </Box>
  );
}
