// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/waitlist/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{\n    font-family: 'Montserrat', sans-serif;\n    padding: 0;\n    margin:0;\n}\n\n\n.bg{\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position:center;\n    height:70vh;\n    /* background-size: auto; */\n    background-size:contain;\n    background-repeat: no-repeat;\n}\n\n@media only screen and (max-width: 500px) {\n    .bg{\n        height: 50vh;\n        background-size:contain;\n        background-repeat: no-repeat;\n    \n    }\n}", "",{"version":3,"sources":["webpack://./src/style/css/waitlist/waitlist.css"],"names":[],"mappings":"AAEA;IACI,qCAAqC;IACrC,UAAU;IACV,QAAQ;AACZ;;;AAGA;IACI,mDAAgD;IAChD,0BAA0B;IAC1B,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI;QACI,YAAY;QACZ,uBAAuB;QACvB,4BAA4B;;IAEhC;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500&display=swap');\n\n*{\n    font-family: 'Montserrat', sans-serif;\n    padding: 0;\n    margin:0;\n}\n\n\n.bg{\n    background: url(../../../images/waitlist/bg.png);\n    background-position:center;\n    height:70vh;\n    /* background-size: auto; */\n    background-size:contain;\n    background-repeat: no-repeat;\n}\n\n@media only screen and (max-width: 500px) {\n    .bg{\n        height: 50vh;\n        background-size:contain;\n        background-repeat: no-repeat;\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
