import {
  Box,
  // Button,
  Grid,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  GrFacebook,
  GrLinkedin,
  //  GrTwitter
} from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
// import { Link } from "react-router-dom"
import logo from "../../images/general/logo_light.png";
// import logo from "../../images/general/logo.svg";
import { Link } from "react-router-dom";
import CustomButton from "../ui/customButton";

const companyLinks = [
  { title: "Customer", to: "/customer" },
  { title: "Entreprise", to: "/enterprise" },
  // { title: "Jobs", to: "/jobsecondpage" },
  { title: "Partners ", to: "/partners" },
  { title: "Jobs", to: "/jobs" },
];

const serviceLinks = [
  { title: "Documentation", to: "/doc" },
  { title: "API References", to: "/doc" },
  { title: "Pricing", to: "/pricing" },
];

const resourceLinks = [
  { title: "Support", to: "/support" },
  { title: "Contact", to: "/contact" },
  { title: "FAQ", to: "/faq" },
  { title: "Privacy & Terms", to: "/" },
];

const contactLinks = [
  {
    title:
      "Address: 21 Nyesi Ibrahim Tella, Oral-Estate, Eleganza Bustop, Lekki - Epe Expy, Lagos ",
    icon: "/",
  },
  { title: "01 630 4704", icon: "/" },
  { title: "0818 332 2155", icon: "/" },
  { title: "support@blacbox.app", icon: "/" },
];

export default function AppFooter() {
  return (
    <Box
      bgcolor={"#111111"}
      pb={{ xs: "51px", sm: "89px", md: "9.26vw" }}
      color="white"
      letterSpacing={"0"}
    >
      <Box
        pb={{ xs: "60px", md: "4.40vw" }}
        pt={{ xs: "55px", sm: "39px", md: "4.05vw" }}
        px={{ xs: "24px" }}
      >
        <Box
          bgcolor={"#FFFFFF"}
          color="black"
          borderRadius={{ xs: "8px", md: "0.58vw" }}
          maxWidth={{ sm: "530px", md: "55.32vw" }}
          pr={{ xs: "46px", sm: "73px", md: "7.64vw" }}
          marginX="auto"
          pl={{ xs: "46px", md: "3.24vw" }}
          py={{ xs: "17px", sm: "23px", md: "2.37vw" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={{ xs: "16px", sm: "48px", md: "4.98vw" }}
            alignItems={"center"}
            justifyContent="center"
          >
            <Box width="100%" maxWidth={{ sm: "224px", md: "23.38vw" }}>
              {/* <Typography fontSize={{md:"15px"}}> CTA Sentence</Typography> */}
              <Typography textAlign={{ xs: "center", sm: "left" }}>
                Start building your fintech product with seamless APIs. Get
                Started.
              </Typography>
            </Box>

            <Box width="100%" textAlign="center" maxWidth={{ md: "16.09vw" }}>
              <CustomButton
                text="Sign Up"
                dark
                href="https://portal.blacbox.app/register"
                target="_blank"
              />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box display={{ xs: "none", sm: "block" }}>
        <Divider sx={{ width: "100%", bgcolor: "white" }} />
      </Box>
      <Box
        minHeight={{ xs: "147px", sm: "235px", md: "333px" }}
        px={{ xs: "58px", md: "4.63vw" }}
        pt={{ xs: "60px", md: "4.40vw" }}
      >
        <Grid
          container
          flexWrap={"wrap"}
          // justifyContent={{ xs: "center", md: "flex-start" }}
          columnSpacing={{ xs: "80px", md: "7.52vw" }}
          rowSpacing={{ xs: "39px" }}
        >
          <Grid item xs={6} md={3}>
            <Box
              width="100%"
              maxWidth={{ xs: "56px", sm: "89px", md: "9.32vw" }}
            >
              <img
                src={logo}
                alt="logo"
                width="100%"
                // style={{ maxWidth: "124px" }}
              />
            </Box>
            <Grid
              my={{ xs: "16px", md: "1.33vw" }}
              container
              // spacing={{ xs: "11px", sm: "18px", md: "25px" }}
              columnSpacing={{ xs: "10px", md: "25px" }}
              // mt="20px"
              // justifyContent={"space-between"}
            >
              {[
                {
                  url: "https://www.linkedin.com/company/blac-box-api/",
                  icon: GrLinkedin,
                },
                {
                  url: "https://www.facebook.com/blacboxapi?mibextid=LQQJ4d",
                  icon: GrFacebook,
                },
                {
                  url: "https://instagram.com/blacboxapi?igshid=YmMyMTA2M2Y=",
                  icon: AiFillInstagram,
                },
                // { url: "", icon: GrTwitter },
              ].map((item, index) => (
                <Grid item xs={5} sm={2} key={index}>
                  <IconButton
                    sx={{ fontSize: { xs: "19px", md: "1.39vw" } }}
                    href={item.url}
                    target="_blank"
                  >
                    <item.icon color="white" fontSize="inherit" />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
            <Typography mt={1} variant="body2">
              {" "}
              All right reserved &copy; 2022{" "}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              fontWeight={"600"}
              variant="subtitle1"
              mb={{ xs: "24px", md: "1.74vw" }}
            >
              Company
            </Typography>
            <List disablePadding dense>
              {companyLinks.map((item) => (
                <Link
                  key={item.title}
                  style={{ textDecoration: "none", color: "inherit" }}
                  to={item.to}
                >
                  <ListItemButton sx={{ mb: { md: "0.93vw" } }} disableGutters>
                    <ListItemText> {item.title} </ListItemText>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              fontWeight={"600"}
              variant="subtitle1"
              mb={{ xs: "24px", md: "1.74vw" }}
            >
              Services
            </Typography>
            <List disablePadding dense>
              {serviceLinks.map((item) => (
                <Link
                  key={item.title}
                  style={{ textDecoration: "none", color: "inherit" }}
                  to={item.to}
                >
                  <ListItemButton sx={{ mb: { md: "0.93vw" } }} disableGutters>
                    <ListItemText> {item.title} </ListItemText>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              fontWeight={"600"}
              variant="subtitle1"
              mb={{ xs: "24px", md: "1.74vw" }}
            >
              Resources
            </Typography>
            <List disablePadding dense>
              {resourceLinks.map((item) => (
                <Link
                  key={item.title}
                  style={{ textDecoration: "none", color: "inherit" }}
                  to={item.to}
                >
                  <ListItemButton sx={{ mb: { md: "0.93vw" } }} disableGutters>
                    <ListItemText> {item.title} </ListItemText>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Grid>
          <Grid item xs={10} md={3}>
            <Typography
              fontWeight={"600"}
              variant="subtitle1"
              mb={{ xs: "24px", md: "1.74vw" }}
            >
              Get in touch:{" "}
            </Typography>
            <List disablePadding dense>
              {contactLinks.map((item) => (
                <ListItem sx={{ mb: { md: "0.53vw" } }} disableGutters>
                  <ListItemText> {item.title} </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
