import { Box, Stack } from '@mui/material';
import yellowsupport from '../../images/support/yellowsupport.png';
import blacksupport from '../../images/support/blacksupport.png';


export default function SupportPageApp() {
    return(
        <>
        <Stack flexDirection="row" mt={{md:"-17px", xs:"-50px"}} justifyContent="space-between">
            <Box>
            <img src={yellowsupport} alt="" width="30" sx={{paddingRight:"20px"}} />
            </Box>
            <Box>
            <img src={blacksupport} alt="" width="20" />
            </Box>
        </Stack>
        </>
    )
}