import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../component/layout/nav";
import GetStarted from "./getStarted";
import DashBoard from "./dashBoard";

import Plan from "./plan";

export default function PricingPage() {
  return (
    <Box>
      <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
        <NavBar />
      </Box>
      <Plan />
      <GetStarted />
      <DashBoard />
    </Box>
  );
}
