import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
  Card,
  IconButton,
} from "@mui/material";
// import AppFooter from "../component/layout/footer"
import { GiPlainSquare } from "react-icons/gi";
import JobAppBar from "../component/layout/jobappbar";
import jobbimageii from "../images/jobappbar/jobbimageii.png";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
// import {AiOutlineDown} from 'react-icons/ai'

export default function JobSecondPage() {
  const jobdetaills = [
    "Finding solutions to allow our customers access healthcare most easily and intuitively.",
    "Setting design requirements based on information from internal teams and user research.",
    "Identifying new product improvement opportunities and propose research to validate these ideas.",
    "Communicating design ideas to a broad range of stakeholders (management, engineers, medics, product) using user flows, process flows, site maps, and wireframes.",
    "Working closely with product managers to suggest improvements for products and processes.",
    "Coordinating with other design team members to follow consistent design guidelines, standards, and best practices. ",
  ];
  const jobmusthave = [
    "Minimum of 4 years of work experience as a product designer/ UI/UX Designer is required.",
    "A strong portfolio packed with clean and inventive interface design is required",
    "A strong understanding of the principles of user-centered design. Knowledge and experience of design guidelines and behavior patterns.",
    "Proficiency in design and prototyping software such as Illustrator, Figma, Webflow, usability tools and other visual design tools is required.",
    "Creative problem-solving designer - not satisfied with the status quo and always thinking of ways to improve.",
    "Be able to recommend new tools and technologies by staying abreast of the latest trends and techniques. ",
  ];

  const morejobscard = [
    {
      title: "Product Designer",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed do eiusmod  tempor incidid",
    },
    {
      title: "product desingner",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed do eiusmod tempor incidid",
    },
    {
      title: "product desingner",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, ed do eiusmod tempor incidid",
    },
  ];

  const navigate = useNavigate();
  return (
    <Box>
      <Box>
        <JobAppBar />
      </Box>

      <Box
        mt={{ xs: "8px", sm: "13px", md: "1.33vw" }}
        px={{ xs: "35px", sm: "55px", md: "5.79vw" }}
      >
        <IconButton
          sx={{ fontSize: { xs: "19px", md: "1.74vw" } }}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft fontSize="inherit" />
        </IconButton>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${jobbimageii})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box sx={{ px: { xs: "35px", sm: "55px", md: "5.79vw" } }}>
          <Box
            sx={{ mx: "auto", mt: { xs: "35px", sm: "55px", md: "5.79vw" } }}
          >
            <Typography
              variant="h5"
              sx={{
                // fontFamily: "Montserrat",
                fontWeight: 600,
                // fontSize: { xs: "19px", md: "23px" },
                color: "#000000",
              }}
            >
              Ideal Candidate
            </Typography>
            <Typography
              // variant="subtitle1"
              fontSize={{ xs: "0.75em", ta: "1.39vw" }}
              sx={{
                fontFamily: "Montserrat",
                fontWeight: 400,
                // fontSize: { sm: "13px", md: "15px" },
                color: "#111111",
                mt: { xs: "7px", sm: "11px", md: "1.16vw" },
              }}
            >
              We are looking for a UI/UX Designer who has a passion for doing
              genuine impacting work and coming up with ways to make it as
              simple and straightforward as possible for our customers to obtain
              healthcare. The ideal applicant will be proficient at every stage
              of the design process while maintaining a constant focus on the
              requirements of the client.
            </Typography>
          </Box>

          <Box mt={{ xs: "27px", sm: "44px", md: "4.57vw" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                // fontSize: { xs: "19px", md: "23px" },
                color: "#000000",
              }}
            >
              You will be responsible for :
            </Typography>
            <List sx={{ mt: { xs: "7px", sm: "11px", md: "1.16vw" } }}>
              {jobdetaills.map((details) => (
                <ListItem key={details} disableGutters disablePadding>
                  <ListItemIcon
                    sx={{
                      minWidth: "0",
                      fontSize: { xs: "13px", sm: " 13px", md: "1.39vw" },
                    }}
                  >
                    <GiPlainSquare
                      style={{
                        color: "#111111",
                        fontSize: "inherit",
                        marginRight: "18px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontFamily: "Montserrat",
                      fontSize: "subtitle1",
                    }}
                    primary={details}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box mt={{ xs: "20px", sm: "32px", md: "3.36vw" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                fontFamily: "Montserrat",
                // fontSize: { xs: "19px", md: "23px" },
                color: "#000000",
              }}
            >
              Must have :{" "}
            </Typography>
            <List sx={{ mt: { xs: "7px", sm: "11px", md: "1.16vw" } }}>
              {jobmusthave.map((items) => (
                <ListItem key={items} disableGutters disablePadding>
                  <ListItemIcon
                    sx={{
                      minWidth: "0",
                      fontSize: { xs: "13px", sm: " 13px", md: "1.39vw" },
                    }}
                  >
                    <GiPlainSquare
                      style={{
                        color: "#111111",
                        fontSize: "inherit",
                        marginRight: "18px",
                      }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    sx={{ m: "0" }}
                    primaryTypographyProps={{
                      fontFamily: "Montserrat",
                      fontSize: "subtitle1",
                    }}
                    primary={items}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={{ mb: { xs: "18px", sm: "29px", md: "3.07vw" } }}>
            <Link to="/jobs/form" style={{ textDecoration: "none" }}>
              <Button
                // variant= "h5"
                sx={{
                  border: "1px solid #111111",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  width: { xs: "147px", sm: "234px", md: "24.42vw" },
                  minHeight: { xs: "38px", sm: "60px", md: "6.25vw" },
                  mt: { xs: "24px", sm: " 30px", md: "3.13vw" },
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: { xs: "16px", sm: "19px", md: "1.74vw" },
                  textAlign: "center",
                  letterSpacing: "0.02em",
                  color: "#111111",
                }}
              >
                Apply Now
              </Button>
            </Link>
          </Box>
        </Box>
        <Stack>
          <Stack
            sx={{
              display: { md: "flex", xs: "none" },
              background: "#DDD3D3",
              borderTopLeftRadius: { xs: "121px", sm: "193px", md: "20.20vw" },
              pb: { xs: "66px", sm: "106px", md: "150px" },
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                mt: { xs: "45px", sm: "72px", md: "7.52vw" },
                fontFamily: "Montserrat",
                fontWeight: 500,
                // fontSize: "31px",
              }}
            >
              More Jobs
            </Typography>

            <Stack
              flexDirection="row"
              textAlign="center"
              justifyContent="center"
              columnGap={{ xs: "17px", sm: "28px", md: "2.89vw" }}
              sx={{
                display: { md: "flex", xs: "none" },
                mt: { xs: "10px", sm: "17px", md: "1.74vw" },
                px: { xs: "52px", sm: "84px", md: "8.74vw" },
              }}
            >
              {morejobscard.map((jobs, index) => (
                <Box
                  sx={{
                    maxWidth: { xs: "153px", sm: "245px", md: "25.58vw" },
                    width: "100%",
                    // mb: { xs: "51px", sm: "82px", md: "116px" },
                  }}
                >
                  <Card
                    key={jobs.more}
                    sx={{
                      px: { xs: "19px", sm: "30px", md: "42px" },
                      height: { xs: "120px", sm: "191px", md: "19.97vw" },
                      borderRadius: { xs: "3px", sm: "6px", md: "0.58vw" },
                      bgcolor: index === 0 ? "#E2C703" : "black",
                      color: index === 0 ? "black" : "white",
                    }}
                  >
                    <Stack
                      // bgColor: index == 0 ? 'yellow' : 'black',
                      height={"100%"}
                      justifyContent="center"
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          // fontSize: "23px",
                          // lineHeight: "29px",
                          textAlign: "center",
                        }}
                      >
                        {jobs.title}
                      </Typography>
                      <Typography
                        sx={{
                          mt: { xs: "7px", sm: "11px", md: "1.16vw" },
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          fontSize: { xs: "6px", sm: "9px", md: "0.93vw" },
                          // lineHeight: "134.5%",
                          textAlign: "center",
                          letterSpacing: "0.007em",
                        }}
                      >
                        {jobs.content}
                      </Typography>

                      {/* <>
                                {
                                    jobs.items.map((display, index) => (
                                        <Typography sx={{mt:"20px"}}>
                                        </Typography>
                                    ))
                                }
                                </> */}
                    </Stack>
                  </Card>
                  <Link to="/jobs/form" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        border: "1px solid #E2C703",
                        borderRadius: "5px",
                        width: { xs: "72px", sm: "114px", md: "11.92vw" },
                        minHeight: { xs: "24px", sm: "38px", md: "3.94vw" },
                        mt: "-24px",
                        mb: { xs: "52px", sm: "83px", md: "8.68vw" },
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        fontSize: { xs: "6px", sm: "9px", md: "0.93vw" },
                        textAlign: "center",
                        letterSpacing: "0.02em",
                        bgcolor: index === 0 ? "black" : "",
                        color: index === 0 ? "white" : "black",
                        variant: index === 0 ? "contain" : "outlined",
                        ":hover": index === 0 ? { bgcolor: "black" } : {},
                      }}
                    >
                      Apply
                    </Button>
                  </Link>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
