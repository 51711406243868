import { Container } from "@mui/system"
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import AppFooter from "../component/layout/footer"
// import NavBar from "../component/layout/nav"

export default function MainLayout() {
  const location = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [location.pathname])

  return (
    <Container disableGutters maxWidth={false}>
      {/* <NavBar dark /> */}
      <main>
        <Outlet />
      </main>
      <AppFooter />
    </Container>
  )
}
