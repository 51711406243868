import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";

import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useState } from "react";

const accordionFaq = [
  {
    id: "01",
    title: "What is BlacBox? ",
    content:
      "BlacBox is a multi-API system that powers all services any business needs to help your business grow faster. ",
  },
  {
    id: "02",
    title: "What are the services BlacBox has? ",
    content:
      "KYC verification, payments, Bank Transfer, OTP services, Bill Payment, Virtual account number, Debit cards, etc ",
  },
  {
    id: "03",
    title: "How does a user or company sign up for BlacBox?",
    content:
      "Signing  up for BlacBox is very simple, simply click the signup button to access the form page where you will enter all your information to begin using our services. ",
  },
];

const MediaCard = ({ title, content, isActive }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box textAlign={"center"}>
      <Stack
        justifyContent={"center"}
        minHeight={{ xs: "132px", md: "11.57vw" }}
        height="100%"
        bgcolor={isActive ? "#111111" : "white"}
        color={!isActive ? "#111111" : "white"}
        boxShadow="0px 0px 13px rgba(17, 17, 17, 0.14)"
        p={{ xs: "14px", sm: "22px", md: "2.31vw" }}
        width={{ md: "20.25vw" }}
      >
        <Typography letterSpacing="0" variant="body1">
          {" "}
          {title}{" "}
        </Typography>
      </Stack>
      <Box
        boxShadow="1px 1px 17px rgba(17, 17, 17, 0.24)"
        bgcolor={!isActive ? "#E2C703" : "white"}
        color={isActive ? "black" : "white"}
        mt={{ xs: "-7px", sm: "-11px", md: "-1.16vw" }}
        width="fit-content"
        height="fit-content"
        py={{ xs: "6px", md: "0.46vw" }}
        px={{ xs: "12px", md: "0.87vw" }}
        mx="auto"
      >
        <IconButton
          onClick={() => setOpen(!open)}
          sx={{
            fontSize: { xs: "23px", md: "1.85vw" },
          }}
        >
          <AiOutlineDown fontSize={"inherit"} fontWeight="500" />
        </IconButton>
      </Box>
      <Box
        display={isActive && open ? "block" : "none"}
        px={{ xs: "14px", sm: "22px", md: "0px" }}
        width={{ md: "20.25vw" }}
        textAlign="justify"
        style={{ hyphens: "auto" }}
      >
        <Typography variant="body1">{content}</Typography>
      </Box>
    </Box>
  );
};

export default function HelpCenter() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <Box>
      <Box
        mx={{ xs: "23px", sm: "auto" }}
        maxWidth={{ sm: "332px", md: "35.24vw" }}
      >
        <Typography variant="h4" textAlign="center" fontWeight="500">
          Find the answers to anything you need from our Help Center
        </Typography>
      </Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"center"}
        columnGap={{ xs: "8px", sm: "13px", md: "1.33vw" }}
        mt={{ xs: "16px", sm: "26px", md: "2.89vw" }}
        className="slide-container"
      >
        {isMobile ? (
          <Slide arrows={false} slidesToShow={1}>
            {accordionFaq.map((item, index) => {
              const isActive = activeIndex === index;
              return (
                <Box
                  onMouseEnter={() => setActiveIndex(index)}
                  textAlign={"center"}
                  className="each-slide"
                  key={index}
                >
                  <MediaCard {...item} isActive={isActive} />
                </Box>
              );
            })}
          </Slide>
        ) : (
          <>
            {accordionFaq.map((item, index) => {
              const isActive = activeIndex === index;
              return (
                <Box onMouseEnter={() => setActiveIndex(index)}>
                  <MediaCard {...item} isActive={isActive} />
                </Box>
              );
            })}
          </>
        )}
      </Stack>
      <Box mt={{ xs: "42px", sm: "49px", md: "2.03vw" }} textAlign="center">
        <Link to={"/faq"} style={{ textDecoration: "none", color: "black" }}>
          <Typography fontSize={{ xs: "8px", md: "0.69vw" }}>
            {" "}
            More questions {<AiOutlineRight />}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
