import { Stack } from "@mui/material"
import { Box } from "@mui/system"
import StyleAccordion from "./styleAccordion"

import apiImage from "../../images/home/home_api.svg"

export default function AccordionBlock({
  data = [],
  columnGap,
  imageProps = {},
}) {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      columnGap={columnGap || { xs: "37px", sm: "58px", md: "83px" }}
      rowGap={"23px"}
    >
      <Box
        width="100%"
        maxWidth={{ md: "48.78vw" }}
        // maxWidth={{ sm: "459px", md: "651px" }}
      >
        <StyleAccordion defaultIndex={0} data={data} activeColor="#E2C703" />
      </Box>
      <Box width="100%" maxWidth={{ sm: "315px", md: "40.74vw" }} {...imageProps}>
        <img src={apiImage} width="100%" alt="home_api_image" />
      </Box>
    </Stack>
  )
}
