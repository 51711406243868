import React from "react";
import { Box, Stack, Typography } from "@mui/material";
//Icons
import { BsCheck2 } from "react-icons/bs";

//image
import coder from "../../images/pricing/coder.png";

export default function Plan() {
  return (
    <Stack
      flexDirection={{ xs: "column", md: "row" }}
      width="100%"
      justifyContent="space-between"
      px={{ xs: "24px", sm: "55px", md: "5.79vw" }}
      // px={{ xs: "24px", sm: "70px", md: "7.29vw" }}
      // pr={{ xs: "24px", sm: "44px", md: "4.57vw" }}
      marginTop={{ xs: "48px", sm: " 52px", md: "5.38vw" }}
    >
      <Box
        minHeight={{ md: "44.04vw" }}
        maxWidth={{ md: "32.00vw" }}
        bgcolor={"#111111"}
        borderRadius={"10px"}
        mr={{ xs: "0px", md: "5.15vw" }}
        px={{ xs: "24px", sm: "26px", md: "2.66vw" }}
        pt={{ xs: "24px", sm: "45px", md: "4.69vw" }}
      >
        <Box>
          <Typography
            color={"#FFFFFF"}
            fontWeight={"500"}
            fontSize={{ xs: "16px", md: "1.39vw" }}
          >
            Building products and Integrating to our API Infrastructure.
            Powering Businesses as Marketplaces, Recurring Payment, KYC
            Verification, Instant Payment Confirmation, etc.
          </Typography>
          <Typography
            variant="body1"
            color={"#FFFFFF"}
            mt={{ xs: "16px", md: "1.16vw" }}
            fontWeight={"400"}
            fontSize={{ xs: "13px", md: "1.04vw" }}
            maxWidth={{ xs: "303px", md: "22.28vw" }}
            // lineHeight={"134.05%"}
            // letterSpacing={"0.007em"}
          >
            Our pricing schemes are simple, allowing you to pay with convenience
            before using or buying any API service
          </Typography>
        </Box>
        <Box
          maxWidth={{ xs: "235px", md: "21.35vw" }}
          mt={{ xs: "66px", sm: " 74px", md: "7.70vw" }}
        >
          <img src={coder} alt="coder" width="100%" />
        </Box>
      </Box>

      <Box
        width={"100%"}
        // display={{ sm: "block", md: "flex" }}
        // gap={"20px"}
        // justifyContent={"space-between"}
        // mt={8}
      >
        {/* <Box display={{xs:"none",md:"block"}} textAlign={"right"}>
          <Typography fontSize={{ xs: "11px", md: "0.81vw" }}>
            Are you a corporate?
          </Typography>
          <Button color="primary">
            <Typography fontSize={{ xs: "11px", md: "0.81vw" }}>
              Contact Us
            </Typography>
          </Button>
        </Box> */}
        <Stack
          mt={{ xs: "24px", md: "3.33vw" }}
          flexDirection={"row"}
          // pr={{xs:"55px",md:"0"}}
          justifyContent="space-between"
          flexWrap="wrap"
          columnGap={{ xs: "24px", md: "2.60vw" }}
          rowGap={{ xs: "57px", md: "2.60vw" }}
        >
          <Box
            sx={{
              background:
                "linear-gradient(148.93deg, #E2D999 -4.42%, rgba(186, 172, 70, 0.74) 40.85%, rgba(0, 0, 0, 0.38) 111.57%)",
            }}
            height={{ sm: "362px", md: "29.11vw" }}
            width={"100%"}
            maxWidth={{ md: "23.15vw" }}
            borderRadius={{ xs: "8px", md: "0.58vw" }}
            px={{ xs: "24px", md: "1.74vw" }}
            pt={{ xs: "43px", md: "2.55vw" }}
            pb={{ xs: " 55px", md: "5.79vw" }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                component="span"
                fontWeight={"700"}
                fontSize={{ xs: "19px", md: "1.85vw" }}
                // lineHeight={"39px"}
                color={"#000000"}
              >
                Blacbox Lite
              </Typography>
              <Typography
                component="span"
                fontWeight={"400"}
                fontSize={{ xs: "13px", md: "0.81vw" }}
                // lineHeight={"17px"}
                color={"#000000"}
              >
                Free
              </Typography>
            </Box>
            <hr style={{ width: "1.04vw", border: "1px solid #485BC0" }} />
            <Box mt={{ xs: "45px", md: "2.84vw" }}>
              <Typography
                fontWeight={"600"}
                fontSize={{ xs: "10px", md: "0.75vw" }}
                // lineHeight={"20px"}
                color={"#000000"}
              >
                Plan Includes:
              </Typography>
              {[
                "Bank List",
                "Bills Payment",
                "Account Verification",
                "2FA Authentication",
              ].map((value) => (
                <Box
                  display={"flex"}
                  fontSize={{ xs: "13px", md: "1.16vw" }}
                  alignItems={"center"}
                  mt={{ xs: "13px", md: "1.16vw" }}
                >
                  <BsCheck2 fontSize="inherit" />
                  <Typography
                    component="span"
                    fontWeight={"500"}
                    fontSize={{ xs: "13px", md: "1.16vw" }}
                    // lineHeight={"20px"}
                    color={"#000000"}
                    ml={{ xs: "12px", md: "0.69vw" }}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            bgcolor={"#111111"}
            height={{ sm: "362px", md: "29.11vw" }}
            width={"100%"}
            maxWidth={{ md: "23.15vw" }}
            borderRadius={{ xs: "8px", md: "0.58vw" }}
            px={{ xs: "24px", md: "1.74vw" }}
            pt={{ xs: "43px", md: "2.55vw" }}
            pb={{ xs: " 55px", md: "5.79vw" }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                component="span"
                fontWeight={"700"}
                fontSize={{ xs: "19px", md: "1.85vw" }}
                // lineHeight={"39px"}
                color={"#FFFFFF"}
              >
                Premium
              </Typography>
            </Box>
            <hr style={{ width: "1.04vw", border: "1px solid #FFFFFF" }} />
            <Box mt={{ xs: "45px", md: "2.84vw" }}>
              <Typography
                fontWeight={"600"}
                fontSize={{ xs: "10px", md: "0.75vw" }}
                // lineHeight={"20px"}
                color={"#FFFFFF"}
              >
                Plan Includes:
              </Typography>
              {[
                "Payment Gateway",
                "Virtual Account Number",
                "Statement of Account",
                "Bank Fetching",
                "Messaging",
              ].map((value) => (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  mt={{ xs: "13px", md: "1.16vw" }}
                >
                  <BsCheck2 fontSize="inherit" color={"#FFFFFF"} />
                  <Typography
                    component="span"
                    fontWeight={"500"}
                    fontSize={{ xs: "13px", md: "1.16vw" }}
                    // lineHeight={"20px"}
                    color={"#FFFFFF"}
                    ml={{ xs: "12px", md: "0.69vw" }}
                  >
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
