import NavBar from "../component/layout/nav";
import {
  Box,
  Typography,
  Card,
  Stack,
  ListItem,
  ListItemText,
} from "@mui/material";
import nav from "../images/support/nav.png";
import { AiFillQuestionCircle } from "react-icons/ai";
// import SupportPageApp from "../component/layout/supportappbar";
import SupportPageApp from "../component/layout/supportappbar";
import supportbimage from "../images/support/supportbimage.png";
// import { Link } from "react-router-dom";
import CustomButton from "../component/ui/customButton";

export default function SupportPage() {
  const supportCard = [
    {
      title: "Sales Support",
      items: [
        {
          icon: AiFillQuestionCircle,
          heading: "Pricing",
          content:
            "Our support team will affirm and authenticate API price rates.",
        },
        {
          icon: AiFillQuestionCircle,
          heading: "Payment Method",
          content:
            "Determine the best payment option for you to use on the platform.",
        },
        {
          icon: AiFillQuestionCircle,
          heading: "Integrations",
          content:
            "Determine which integration services would be most beneficial to your company.",
        },
      ],
    },

    {
      title: "Product Support",
      items: [
        {
          icon: AiFillQuestionCircle,
          heading: "Documentation",
          content:
            "Contact us if you need assistance with working with Blacbox documentation for your business.",
        },
        {
          icon: AiFillQuestionCircle,
          heading: "API reference",
          content:
            "Discover the best API solution and reference for  your service.",
        },
        {
          icon: AiFillQuestionCircle,
          heading: "Verification",
          content:
            "Resolve any business verification issues that may have arisen during sign-up.",
        },
      ],
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${nav})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // minHeight: {xs:"112px", sm:"179px", md:"18.63vw"},
        }}
      >
        <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}> 
          <NavBar light />
        </Box>
        <Box
          sx={{
            mt: { xs: "23px", sm: "37px", md: "3.82vw" },
            pb: { xs: "40px", sm: "63px", md: "6.60vw" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              // fontFamily: "Montserrat",
              fontWeight: 600,

              // lineHeight: "61px",
              textAlign: "center",
              color: "#FFFFFF",
              // mt:{xs:"22px", sm:"34px", md:"49px"},
              // mb:{xs:"39px", sm:"62px", md:"88px"},
            }}
          >
            Support
          </Typography>
        </Box>
      </Box>
      <Box display={{xs:"none",sm:'block'}}>
        <SupportPageApp />
      </Box>

      <Box
        sx={{
          backgroundImage: { xs: "", sm: `url(${supportbimage})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          //   mt: "-40px",
        }}
      >
        <Stack
          px={{ xs: "23px", sm: "98px" }}
          pr={{ md: "181px" }}
          pl={{ md: "11.46vw" }}
          mt={{ xs: "40px", sm: "63px", md: "6.60vw" }}
          columnGap={{ xs: "43px", sm: "69px", md: "7.23vw" }}
          rowGap={{ xs: "43px", sm: "69px", md: "7.23vw" }}
          // flexWrap="wrap"
          flexDirection={{ xs: "column", md: "row" }}
          //   sx={{ flexDirection: { xs: "column", md: "row", sm: "row" } }}
          //   justifyContent="space-around"
        >
          {/* hewdguefwhhfuk */}
          {supportCard.map((item) => (
            <Card
              key={item.display}
              sx={{
                width: "100%",
                minHeight: { xs: "294px", sm: "358px", md: "29.57vw" },
                pb: { xs: "39px", sm: "62px", md: "6.54vw" },
                // minWidth: {   md: "152px" },
                maxWidth: { md: "33.85vw" },
                backgroundColor: "#F6F6F6",
                // mt: "20px",
                // mx: {xs:"43px", sm:"69px", md:"7.23vw"},
              }}
            >
              <Box
                sx={{
                  mx: { xs: "17px", sm: "20px", md: "2.89vw" },
                  // mt: { xs: "22px", sm: "34px", md: "3.65vw" },
                }}
                maxWidth={{ sm: "232px", md: "33.85vw" }}
              >
                <Typography
                  variant="h5"
                  mb={{ xs: "10px", sm: "17px", md: "1.74vw" }}
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "525",
                    // fontSize: "30px",
                    lineHeight: "37px",
                    color: "#111111",
                    mt: { xs: "22px", sm: "35px", md: "3.65vw" },
                    // pl:{xs:"17px", sm:"28px", md:"2.89vw"},
                  }}
                >
                  {item.title}
                </Typography>
                <>
                  {item.items.map((child) => (
                    <ListItem
                      disableGutters
                      disablePadding
                      sx={{
                        alignItems: "flex-start",
                        // border:"solid red",
                        // mt: { xs: "10px", sm: "16px", md: "1.74vw"},
                      }}
                    >
                      <Box sx={{ fontSize: { xs: "17px", md: "1.27vw" } }}>
                        <child.icon fontSize="inherit" />
                      </Box>
                      <ListItemText
                        sx={{
                          m: 0,
                          mx: { xs: "11px", sm: "17px", md: "1.79vw" },
                        }}
                        primary={child.heading}
                        primaryTypographyProps={{
                          // mb: { xs: "5px", md: "8px" },
                          mb: { xs: "3px", sm: "6px", md: "0.58vw" },
                          fontWeight: "500",
                          fontSize: { xs: "13px", md: "0.93vw" },
                          // mx:{xs:"11px", sm:"17px", md:"1.79vw"},
                        }}
                        secondary={child.content}
                        secondaryTypographyProps={{
                          variant: "caption",
                          mb: { xs: "10px", sm: "17px", md: "1.74vw" },
                        }}
                      />
                    </ListItem>
                  ))}
                </>
              </Box>
            </Card>
          ))}
        </Stack>
        <Stack
          alignItems={"center"}
          mt={{ xs: "33px", sm: "53px", md: "75px" }}
          pb={{ xs: "24px", sm: "38px", md: "53px" }}
          px={{ xs: "35px", sm: "0" }}
        >
          <CustomButton
            text="Submit a Request"
            to="/contact"
            sx={{ maxWidth: { sm: "234px", md: "24.42vw" } }}
          />
          <Typography
            sx={{
              mt: { xs: "5px", sm: "8px", md: "12px" },
              textAlign: "center",
            }}
          >
            <small>Can't find an answer to your question?</small>
          </Typography>
        </Stack>
      </Box>
      {/* </Box> */}
    </Box>
  );
}
