import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import Validator from "validatorjs";
import { useState } from "react";

Validator.register(
  "alpha_spaces",
  (value) => {
    let reg = /^[A-Za-z ]+$/;
    return reg.test(value);
  },
  "The :attribute may only contain letters and spaces."
);

const validationRules = {
  name: "required|alpha_spaces",
  companyName: "required",
  email: "required|email",
  phone: "required|numeric|digits:11",
};

export default function WaitForm() {
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false)
  const [errorResponseMessage, setErrorResponseMessage] = useState("");
  const [sucessResponseMessage, setSuccessResponseMessage] = useState("");

  const submit = (e) => {
    e.preventDefault();
    const inputs = e.target;
    const data = {};
    let emailBody =  ''
    
    setErrorMessage({});
    setErrorResponseMessage("")
    setSuccessResponseMessage("")

    Object.values(inputs).forEach((field) => {
      if (field.name) {
        emailBody+=`<p>${field.name} : ${field.value}<p/>`
        data[field.name] = field.value;
      }
    });

    let validation = new Validator(data, validationRules);
    if (validation.fails()) {
      return setErrorMessage(validation.errors.errors);
    }
    setLoading(true)
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "blacbox-support@wallx.co",
      Password: "0B83DF84BE3A04BE0E64944F30D0D15F7148",
      To: "blacbox@wallx.co",
      From: "blacbox@wallx.co",
      Subject: `Waitlist Form from ${data.name}` ,
      Body: `${emailBody}`,
    })
      .then((resp) => {
        if(resp !== "OK"){
          setErrorResponseMessage("An error occured, please try again later or contact support if this error persist")
          return 
        }
        e.target.reset()
        setSuccessResponseMessage("You have been sucessfully added to the waitlist, you will hear from us soon")
      })
      .catch((e) => {
        setErrorResponseMessage("An error occured, please try again later or contact support if this error persit")
      }).finally(()=>{
        setLoading(false);

      });
  };


  const isLoading = loading;
  return (
    <Stack
      spacing={{ xs: "20px", sm: "32px", md: "3.36vw" }}
      as="form"
      target="hiddenFrame"
      onSubmit={submit}
      action="https://tech.us6.list-manage.com/subscribe/post-json"
      method="POST"
      px={{ xs: "65px", sm: "104px", md: "10.88vw" }}
      sx={{ marginTop: { xs: "33px", sm: "53px", md: "5.56vw" } }}
    >
      <div class="form__group">
        <TextField
          fullWidth
          variant="standard"
          name="name"
          error={errorMessage?.name}
          helperText={errorMessage?.name?.[0] || ""}
          placeholder="Name"
          inputProps={{
            sx: {
              color: "white",
              "&::placeholder": {
                color: "white",
                opacity: 1,
              },
              pb: { xs: "31px", md: "1.74vw" },
              fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
              borderBottom: {
                xs: "1px dashed white",
                md: "0.12vw dashed white",
              },
            },
          }}
        />
      </div>
      <div class="form__group">
        <TextField
          fullWidth
          variant="standard"
          error={errorMessage?.phone}
          helperText={errorMessage?.phone?.[0] || ""}
          name="phone"
          type="tel"
          placeholder="Phone"
          inputProps={{
            sx: {
              color: "white",
              "&::placeholder": {
                color: "white",
                opacity: 1,
              },
              pb: { xs: "31px", md: "1.74vw" },
              fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
              borderBottom: {
                xs: "1px dashed white",
                md: "0.12vw dashed white",
              },
            },
          }}
        />
      </div>
      <div class="form__group">
        <TextField
          fullWidth
          variant="standard"
          name="email"
          error={errorMessage?.email}
          helperText={errorMessage?.email?.[0] || ""}
          type="email"
          placeholder="Email"
          inputProps={{
            sx: {
              color: "white",
              "&::placeholder": {
                color: "white",
                opacity: 1,
              },
              pb: { xs: "31px", md: "1.74vw" },
              fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
              borderBottom: {
                xs: "1px dashed white",
                md: "0.12vw dashed white",
              },
            },
          }}
        />
      </div>
      <div class="form__group">
        <TextField
          fullWidth
          variant="standard"
          error={errorMessage?.companyName}
          helperText={errorMessage?.companyName?.[0] || ""}
          name="companyName"
          placeholder="Company Name"
          inputProps={{
            sx: {
              color: "white",
              "&::placeholder": {
                color: "white",
                opacity: 1,
              },
              pb: { xs: "31px", md: "1.74vw" },
              fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
              borderBottom: {
                xs: "1px dashed white",
                md: "0.12vw dashed white",
              },
            },
          }}
        />
      </div>
      <Box>
        {errorResponseMessage && (
          <Alert severity="error">{errorResponseMessage}</Alert>
        )}
        {sucessResponseMessage && (
          <Alert severity="success">{sucessResponseMessage}</Alert>
        )}
      </Box>
      <Button
        type="submit"
        disabled={isLoading}
        sx={{
          backgroundColor: isLoading ? "#aaa" : "#E2C703",
          fontSize: { xs: "10px", sm: "17px", md: "1.74vw" },
          textTransform: "none",
          color: "black",
          width: { xs: "147px", sm: "234px", md: "24.42vw" },
          height: { xs: "38px", sm: "60px", md: "6.25vw" },
          "&:hover": { backgroundColor: "#E2C703", color: "black" },
          marginBottom: { xs: "38px", sm: "60px", md: "6.25vw" },
        }}
      >
        {isLoading ? (
          <CircularProgress size={"2em"} variant="indeterminate" />
        ) : (
          "Submit"
        )}
      </Button>
    </Stack>
  );
}
