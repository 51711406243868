import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  List,
  Card,
  ListItem,
  ListItemText,
} from "@mui/material";
import jobpagenavimage from "../images/jobappbar/jobpagenavimage.png";
import jobpagebainmag from "../images/jobappbar/jobpagebainmag.png";
import jobpagemobileimage from "../images/jobappbar/jobpagemobileimage.png";
import jobimage from "../images/jobappbar/jobimage.png";
import occasioImage from "../images/jobappbar/job.png";
import NavBar from "../component/layout/nav";
// import jobth
import { useState } from "react";
import { Link } from "react-router-dom";

import slideImage02 from "../images/general/slide/slideImage02.svg";
import slideImage03 from "../images/general/slide/slideImage03.svg";
import slideImage01 from "../images/general/slide/slideImage.svg";
import VerticalBar from "../component/ui/verticalBar";

// const tools = [
//   {
//     title: "Developers Tools",
//     text: "We've integrated simple programs that enable your developer to build and test APIs.",
//     img: jobimage,
//   },
//   {
//     title: "Authentication",
//     text: "Enabling a robust authenticator to corroborate user identity and accelerate integration.",
//     img: jobpagebainmag,
//   },
// ]

const slideList = [
  { title: "Flexibility and speed", image: slideImage01 },
  { title: "Customer-centric", image: slideImage02 },
  { title: "Top-notch integrations", image: jobimage },
  { title: "Trust and integrity", image: slideImage03 },
];

export default function JobPage() {
  const morejobscard = [
    {
      title: "Product Designer",
      content: "Position available for product designer",
    },
    {
      title: "Software Developer",
      content: "Apply for the role of software developer",
    },
    {
      title: "Digital Markater",
      content: "Apply for the position of DevOps",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [applyndex, setApplyIndex] = useState(0);

  return (
    <Box>
      <Box
        sx={{
          background:"black",
          backgroundImage: {
            xs: `url(${jobpagemobileimage})`,
            sm: `url(${jobpagenavimage})`,
          },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition:{xs:"0 73px"},
          pb: { xs: "43px", sm: " 91px", md: "9.49vw" },
          // height: { xs: "290px", sm: "460px", md: "460px" },
        }}
      >
        <Box px={{ xs: "24px", sm: "55px", md: "5.79vw" }}>
          <NavBar light />
        </Box>
        <Box mx={{ xs: "43px", md: "0" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: { xs: "31px", md: "2.31vw" },
              color: "#FFFFFF",
              mt: { xs: "73px", sm: " 105px", md: "10.94vw" },
              textAlign: "center",
            }}
          >
            Together, Let’s Accomplish the Unimaginable in Africa
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "13px", md: "1.04vw" },
              // lineHeight: {xs:"46px", sm:"73px", md:"104px"},
              textAlign: "center",
              color: "#FFFFFF",
              mx: "auto",
              mt: { xs: "8px", sm: " 11px", md: "1.16vw" },
              maxWidth: { xs: "285px", sm: " 354px", md: "36.98vw" },
            }}
          >
            Join our team as we will help you in realizing your full potential
            as you work with us to create a fully accessible payments
            infrastructure.
          </Typography>
        </Box>
        <Box
          mt={{ xs: "24px", sm: " 38px", md: "3.94vw" }}
          sx={{
            textAlign: "center",
          }}
        >
          <Link to="/jobs/apply" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                border: "1px solid #E2C703",
                borderRadius: "5px",
                width: "100%",
                minWidth: { xs: "40.00vw", sm: "fit-content" },
                maxWidth: { xs: "fit-content", sm: "40.00vw", md: "24.42vw" },
                height: { xs: "50px", sm: " 60px", md: "6.25vw" },
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: { xs: "13px", sm: " 17px", md: "1.74vw" },
                textAlign: "center",
                color: "#FFFFFF",
              }}
            >
              <Typography variant="h5" noWrap fontWeight="600">
                Join Our Team
              </Typography>
            </Button>
          </Link>
        </Box>
      </Box>

      <Stack
        pr={{ xs: "23px", md: "66px" }}
        pl={{ xs: "23px", md: "0" }}
        direction="row"
        position="relative"
      >
        <Box
          display={{ xs: "none", md: "block" }}
          minHeight={{ xs: "0", md: "37.85vw" }}
          width="100%"
          mr={{ xs: "0", md: "4.86vw" }}
          maxWidth={{ xs: "0", md: "14.24vw" }}
        >
          <img src={jobpagebainmag} alt="" width="100%" />
        </Box>

        <Box
          mt={{ xs: "39px", sm: " 45px", md: "4.69vw" }}
          // mb={{md:"6.31vw"}}
          bgcolor={{ xs: "none", md: "#FEFBE7" }}
          height={{ md: "26.91vw" }}
          // height={"fit-content"}
          pt={{ ta: "4.05vw" }}
          px={{ md: "2.03vw" }}
          pb={{ md: "1.39vw" }}
          width="100%"
          // minHeight={{ xs: "159px", sm: "253px", md: "359px" }}
          maxWidth={{ md: "33.74vw" }}
        >
          {/* <Box>
            <BorderLinearProgress variant="determinate" value={20} />
          </Box> */}
          <Typography
            // mt={{ xs: "24px", sm: "38px", md: "54px" }}
            letterSpacing={{ xs: "0" }}
            // letterSpacing={{xs:"0",md:"0.08em"}}
            variant="subtitle1"
            fontWeight="500"
            width={{ xs: "75.00vw", md: "100%" }}
            fontSize={{ xs: "19px", md: "1.39vw" }}
            // fontWeight={"500"}
          >
            On the mission of putting together a talented team to develop agile
            products
          </Typography>
          <Stack
            sx={{}}
            flexDirection="row"
            // mt={{ xs: "6px", sm: "9px", md: "13px" }}
            maxWidth={{ md: "20.37vw" }}
          >
            {/* <Stack
              mt={{ xs: "0.00vw", ta: "1.66vw" }}
              alignItems={"center"}
              rowGap={{ xs: "0.00vw", ta: "1.39vw" }}
              display={{ xs: "none", ta: "flex" }}
            >
              <BorderLinearProgress
                sx={{
                  height: { xs: "0.00vw", ta: "9.26vw" },
                  width: { xs: "0.00vw", ta: "0.23vw" },
                }}
                variant="determinate"
                value={((3 - activeIndex) / 4) * 100}
              />
              <Box
                sx={{
                  transform: "rotate(-90deg) translateX(-50%)",
                }}
              >
                <Typography fontSize={{ xs: "0.00em", ta: "0.75em" }}>
                  0{activeIndex + 1}/<span style={{color:"#9A9A9A"}}>04</span>
                </Typography>
              </Box>
            </Stack> */}
            <VerticalBar value={activeIndex} total={4} />
            <List>
              {slideList.map((item, index) => {
                const isActive = index === activeIndex;
                return (
                  <>
                    <ListItem
                      onMouseOver={() => setActiveIndex(index)}
                      key={item.title}
                      sx={{
                        bgcolor: isActive ? "#E2C703" : "transparent",
                        cursor: "pointer",
                        // height: "56px",
                        px: { xs: "19px", md: "1.39vw" },
                        py: { xs: "2.80vw", ta: "0.81vw" },
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: isActive ? "600" : "400",
                          fontFamily: "Montserrat",
                          letterSpacing: "0",
                          fontSize: isActive
                            ? { xs: "14px", md: "1.16vw" }
                            : { xs: "13px", md: "1.04vw" },
                          color: isActive ? "white" : "#000000",
                        }}
                        primary={item.title}
                      />
                    </ListItem>

                    <Box
                      width="100%"
                      sx={{
                        display: {
                          md: "none",
                          xs: index === activeIndex ? "inline" : "none",
                        },
                      }}
                    >
                      <img src={item.image} alt="" width={"100%"} />
                    </Box>
                  </>
                );
              })}
            </List>
          </Stack>
        </Box>

        <Box
          // position={"absolute"}
          width="100%"
          // mt={{ xs: "39px", sm: " 45px", md: "4.69vw" }}
          // ml="-50px"
          // right="7.06vw"
          // pt={{md:"3.01vw"}}
          // maxWidth={{ md: "43.63vw" }}
          mt={{ xs: "0.00vw", ta: "7.70vw" }}
          // mb={"-3.47vw"}
          height="26.79vw"
          // top="0"
          zIndex="-1"
          // minHeight={{ md: "27.37vw" }}
          // bottom={{ xs: "46px", sm: "73px", md: "-1.47vw" }}
          ml={{ xs: "46px", sm: "73px", md: "-3.47vw" }}
          // height={{ xs: "161px", sm: "258px", md: "365px" }}
          // maxWidth={{ xs: "161px", sm: "258px", md: "365px" }}
          display={{ xs: "none", md: "block" }}
        >
          <img
            src={slideList[activeIndex].image}
            // height="100%"
            alt=""
            width={"100%"}
          />
        </Box>
      </Stack>

      <Box
        borderRadius={{
          xs: "138px 0 0 0 ",
          sm: "193px 0 0 0 ",
          md: "20.20vw 0 0 0 ",
        }}
        bgcolor={"#F6F6F6"}
        color="#000000"
        mt={{ xs: "18px", sm: " 36px", md: "3.76vw" }}
        pt={{ xs: "39px", sm: " 63px", md: "6.54vw" }}
        px={{ xs: "58px", sm: " 84px", md: "8.74vw" }}
        pb={{ xs: "39px", sm: " 95px", md: "9.95vw" }}
      >
        <Box
          mx="auto"
          mb={{ xs: "24px", sm: " 37px", md: "3.82vw" }}
          maxWidth={{ xs: "53.40vw", md: "43.40vw" }}
        >
          <Typography
            fontWeight={"500"}
            letterSpacing="2%"
            lineHeight={{ xs: "23px", sm: "36px", md: "51px" }}
            textAlign="center"
            fontSize={{ xs: "1.25em", md: "2.31vw" }}
          >
            Apply to Join our Growing Team{" "}
          </Typography>
        </Box>

        <Stack
          flexDirection="row"
          textAlign="center"
          flexWrap="wrap"
          justifyContent="center"
          columnGap={{ xs: "39px", md: "2.89vw" }}
          rowGap={{ xs: "39px", md: "2.89vw" }}
        >
          {morejobscard.map((jobs, index) => {
            const isActive = applyndex === index;
            return (
              <Box
                onMouseOver={() => setApplyIndex(index)}
                sx={{
                  width: "100%",
                  maxWidth: { xs: "277px", md: "25.58vw" },
                  margin: "auto",
                  height: "100%",
                  // border: "solid red"
                }}
              >
                <Card
                  key={jobs.more}
                  sx={{
                    px: { xs: "25px", md: "1.85vw" },
                    width: "100%",
                    // pb: { xs: "11px", sm: "40px", md: "90px" },
                    // width: { xs: "90%", sm: "251px", md: "341px" },
                    height: { xs: "174px", sm: " 196px", md: "20.49vw" },
                    // height:"100%",
                    borderRadius: { xs: "8px", md: "0.58vw" },
                    bgcolor: isActive ? "#E2C703" : "black",
                    color: isActive ? "black" : "#FFFFFF",
                  }}
                >
                  <Stack
                    height="100%"
                    // justifyContent={"center"}
                  >
                    <Typography
                      sx={{
                        // mt: { xs: "7px", sm: "11px", md: "15px" },
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        fontSize: { xs: "1.00em", md: "1.74vw" },
                        textAlign: "center",
                        mt: { xs: "11.20vw", sm: "6.66vw" },
                      }}
                    >
                      {jobs.title}
                      <ListItemText
                        primary={jobs.content}
                        primaryTypographyProps={{
                          fontSize: { xs: "0.67em", md: "1.04vw" },
                        }}
                        sx={{
                          mt: { xs: "7px", sm: "11px", md: "15px" },
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          textAlign: "center",
                          // maxWidth: { xs: "129px", sm: "80%", md: "11.23vw" },
                          margin: "auto",
                        }}
                      />
                    </Typography>
                  </Stack>
                </Card>
                <Link to="/jobs/apply" style={{ textDecoration: "none" }}>
                  <Button
                    sx={{
                      border: "1px solid #E2C703",
                      borderRadius: "5px",
                      maxWidth: { xs: "162px", md: "11.92vw" },
                      width: "100%",
                      minHeight: { xs: "53px", md: "3.94vw" },
                      mt: { xs: "-19px", md: "-1.39vw" },
                      mb: "30px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { xs: "13px", md: "0.93vw" },
                      textAlign: "center",
                      bgcolor: isActive ? "black" : "",
                      color: isActive ? "white" : "black",
                      variant: isActive ? "contain" : "outlined",
                    }}
                  >
                    Apply
                  </Button>
                </Link>
              </Box>
            );
          })}
        </Stack>

        <Box
          mx="auto"
          px={{ xs: "14px", sm: "0" }}
          mt={{ xs: "39px", sm: " 108px", md: "11.23vw" }}
          // mb={{xs:"39px", sm:"62px", md:"87px"}}
          maxWidth={{ xs: "223px", sm: " 416px", md: "43.40vw" }}
        >
          <Typography
            variant="h4"
            fontWeight={"500"}
            letterSpacing="2%"
            // px="14px"
            lineHeight={{ xs: "23px", sm: "36px", md: "51px" }}
            textAlign="center"
          >
            Recent Occasions and Engagements{" "}
          </Typography>
        </Box>

        <Box
          pl={{ xs: "14px", sm: "58px", md: "82px" }}
          pr={{ xs: "14px", sm: "22px", md: "31px" }}
          mt={{ xs: "30px", md: "2.26vw" }}
        >
          <Grid
            container
            alignItems={"center"}
            spacing={{ xs: "31px", sm: "49px", md: "70px" }}
          >
            <Grid item xs={12} sm={6} md={7}>
              <img src={occasioImage} alt="api_image" width={"100%"} />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              textAlign={{ xs: "center", sm: "left" }}
              alignItems={"center"}
              // mt={{ xs: "29px", sm: "40px", md: "120px" }}
            >
              <Typography
                // mt={{ xs: "11px", sm: "17px", md: "24px" }}
                // fontSize={{ xs: "10px", sm: "16px", md: "23px" }}

                variant="h5"
                fontSize={{ xs: "24px", md: "2.31vw" }}
                fontWeight={"400"}
              >
                Celebrating workers Day
              </Typography>
              <Typography
                mt={{ xs: "7px", sm: "11px", md: "15px" }}
                // mb={{ xs: "29px", sm: "47px", md: "66px" }}
                fontWeight={"400"}
              >
                For the Blacbox employees, TeamBonding leads the best-in-class
                team-building exercises.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
